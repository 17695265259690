import React from 'react';

import { GeneralFailedState } from '@/components/CallDetailsPage/FailStates/GeneralFailedState';
import { NotSalesCallFailedState } from '@/components/CallDetailsPage/FailStates/NotSalesCallFailedState';
import { InviteAssistantAgain } from '@/components/CallDetailsPage/InviteAssistantAgain';
import { CallFailedCode } from '@/enums/call-failed-code.enum';
import { Call } from '@/interfaces/call.interface';

import { DoneState } from './DoneState';

interface FailedStateParams {
  call: Call;
  openDeleteCallModal: () => void;
}

export const FailedState: React.FC<FailedStateParams> = ({
  call,
  openDeleteCallModal,
}) => {
  if (call.failedCode === CallFailedCode.NotSalesCall) {
    return (
      <NotSalesCallFailedState
        callId={call.id}
        openDeleteCallModal={openDeleteCallModal}
      />
    );
  }

  if (call.failedCode === CallFailedCode.BotRecordingFailed) {
    return <InviteAssistantAgain callId={call.id} />;
  }

  if (call.failedCode === CallFailedCode.CallTooShort) {
    //Return the Done State, we will do changes in this to make it work and show only the transcript and the recording
    return <DoneState />;
  }

  return <GeneralFailedState />;
};
