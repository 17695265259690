/* eslint-disable react-hooks/exhaustive-deps */
import { Tab } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import {
  getInsightsChartInfo,
  GetInsightsChartInfoResponse,
} from '@/api/insights/getInsightsChartInfo';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsAcrossCallsTimeRangeDropdown } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';
import {
  SubscriptionBanner,
  SubscriptionBannerPage,
} from '@/components/subscription/SubscriptionBanner/SubscriptionBanner';
import { usePageTracking } from '@/hooks/usePageTracking';
import { useSubscription } from '@/hooks/useSubscription';
import cn from '@/utils/cn';
import { insightsTabs } from '@/utils/insights-tabs';

export const InsightsAcrossCallsPage = () => {
  const { subscriptionData } = useSubscription();
  const {
    setRawChartData,
    selectedDateOption,
    setIsChartLoading,
    setCallsInProcessingState,
    setSelectedTimeRangeCallsCount,
    setHasAtLeastOneCall,
    setOrganizationLastReanalyzedIndex,
  }: InsightsAcrossCallsStore = useInsightsAcrossCallsStore();
  const [tabIndex, setTabIndex] = useState(0);

  const chartResult = useQuery<GetInsightsChartInfoResponse | undefined>({
    queryFn: getInsightsChartInfo(selectedDateOption?.value),
    queryKey: ['insightsChartInfo', selectedDateOption],
    enabled: !!selectedDateOption,
  });

  const chartResultData: GetInsightsChartInfoResponse | undefined =
    chartResult.data;

  useEffect(() => {
    if (!chartResultData) {
      return;
    }

    setRawChartData(chartResultData.chartData);
    setCallsInProcessingState(chartResultData.callsInProcessingState);
    setSelectedTimeRangeCallsCount(chartResultData.selectedTimeRangeCallsCount);
    setHasAtLeastOneCall(chartResultData.hasAtLeastOneCall);
    setOrganizationLastReanalyzedIndex(
      chartResultData.organizationMetadata.lastReanalyzedIndex,
    );
    setIsChartLoading(false);
  }, [chartResultData]);

  const insightsTabTitle = insightsTabs[tabIndex].title.replace(' ', '');
  usePageTracking(`Insights${insightsTabTitle}`);

  const handleOnChange = (index: number) => {
    setTabIndex(index);
  };

  // This is commented out because it is not used in the code but if at some point we need to have it back we can have it
  //const displayCallsNumber = (): string => {
  //  if (!selectedTimeRangeCallsCount || isLoading) {
  //    return 'Insights across your calls';
  //  }

  //  switch (true) {
  //    case selectedTimeRangeCallsCount === 1:
  //      return `Congrats, you've had ${selectedTimeRangeCallsCount} call over the ${selectedDateOption?.label.toLowerCase()}!`;
  //    case selectedTimeRangeCallsCount > 1:
  //      return `Congrats, you’ve had ${selectedTimeRangeCallsCount} calls over the ${selectedDateOption?.label.toLowerCase()}!`;
  //    default:
  //      return 'Insights across your calls';
  //  }
  //};

  // const isLoading = isPageDataLoading || isChartLoading;

  const TabSubHeader = () => {
    switch (tabIndex) {
      case 0:
        return 'The graph below measures what share of the playbook was completed in each call, over time';
      case 1:
        return 'The graph below measures speaking skills for each call, over time';
      case 2:
        return "Below is a list of the questions and objections you've been asked most often across your calls, grouped by topic";
      default:
        return '';
    }
  };

  return (
    <div className="lg:px-24 w-full mt-8 mb-10">
      <SubscriptionBanner
        className="mb-10"
        page={SubscriptionBannerPage.Insights}
      />
      <div className="flex items-center justify-between  mb-[32px]">
        <div className="flex flex-col gap-1rem">
          <p className="text-dark text-[22px] font-bold gap-2">
            Insights across calls
          </p>
          <div className="text-main-gray-dark text-base font-medium">
            {TabSubHeader()}
          </div>
        </div>
        <WithHoverTooltip
          tooltip={
            subscriptionData?.isDemoMode &&
            'Upload your first meeting to change the date range'
          }
          placement="bottom-end"
        >
          <InsightsAcrossCallsTimeRangeDropdown />
        </WithHoverTooltip>
      </div>
      <Tab.Group onChange={handleOnChange} selectedIndex={tabIndex}>
        <Tab.List className="flex gap-6 items-center mt-8 border-b border-b-main-gray">
          {insightsTabs.map(({ key, title }) => (
            <Tab
              key={key}
              className="flex flex-col relative items-center gap-2 focus:ring-0 focus:outline-none"
            >
              {({ selected }) => (
                <>
                  <span
                    className={cn(
                      selected
                        ? 'text-dark font-semibold'
                        : 'text-main-gray-dark font-medium',
                      'text-sm py-3',
                    )}
                  >
                    {title}
                  </span>
                  {selected && (
                    <div className="absolute bottom-0 h-1 w-full bg-accent-blue"></div>
                  )}
                </>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {insightsTabs.map(({ key, panel }) => (
            <Tab.Panel key={key} unmount={true} className="mt-6">
              {panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
