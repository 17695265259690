import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSignUpSlice } from '../../pages/SignUpPage/slice';
import { selectVerifiedEmail } from '../../pages/SignUpPage/slice/selectors';
import { validateEmail } from '../../utils/validators/validateEmail';
import { Button } from '../Button/Button';
import { EmailInput } from '../Input/EmailInput';
import { Label } from '../Label/Label';

interface CheckEmailFormProps {
  formType: 'signIn' | 'signUp';
}

export const CheckEmailForm: React.FC<CheckEmailFormProps> = ({ formType }) => {
  const dispatch = useDispatch();
  const { actions } = useSignUpSlice();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const verifiedEmail = useSelector(selectVerifiedEmail);

  useEffect(() => {
    if (verifiedEmail) {
      setEmail(verifiedEmail);
    }
  }, [verifiedEmail]);

  const handleContinueClick = () => {
    if (email) {
      const isEmailValid = validateEmail(email);
      setIsEmailValid(isEmailValid);
    }

    dispatch(actions.checkIfEmailNotUsed({ email }));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEmailValid) {
      const isEmailValid = validateEmail(event.target.value);
      setIsEmailValid(isEmailValid);
    }
    setEmail(event.target.value);
  };

  const redirect = () => {
    switch (formType) {
      case 'signIn':
        navigate('/auth/register');
        break;
      case 'signUp':
        navigate('/auth/login');
        break;
    }
  };

  const redirectTitleText = () => {
    switch (formType) {
      case 'signIn':
        return "Don't have account yet?";
      case 'signUp':
        return 'Already have account?';
    }
  };

  const redirectButtonText = () => {
    switch (formType) {
      case 'signIn':
        return 'Sign up';
      case 'signUp':
        return 'Sign In';
    }
  };

  /**
   *  Handle Enter key press
   * @param event  - Keyboard event
   */
  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      handleContinueClick();
    }
  };

  return (
    <div className="flex flex-col rounded-lg w-[400px]">
      {!isEmailValid && (
        <span className="text-sm font-medium text-accent-red mb-6">
          Please enter valid work email. <br />
          Example: john@workemail.com
        </span>
      )}
      <div className="mb-4">
        <Label htmlFor="email" isError={!isEmailValid}>
          Work email
        </Label>
        <EmailInput
          id="email"
          name="email"
          placeholder="Enter your work email to continue..."
          value={email}
          onChange={handleEmailChange}
          onKeyDown={handleEnterKeyPress}
          className="placeholder:text-sm"
          required
          isError={!isEmailValid}
        />
      </div>
      <Button onClick={handleContinueClick} disabled={!email || !isEmailValid}>
        Continue
      </Button>
      <div className="flex flex-row items-center justify-start text-left mt-6 ">
        <span className="text-dark font-medium text-sm ">
          {redirectTitleText()}
        </span>
        <Button onClick={redirect} variant="link">
          {redirectButtonText()}
        </Button>
      </div>
    </div>
  );
};
