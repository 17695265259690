import React, { useState } from 'react';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Shared/Checkbox';
import Modal from '@/components/Shared/Modal';

interface HeadphonesWarningModalProps {
  show: boolean;
  onClose: () => void;
  onAccept: (params: { showAgain: boolean }) => void;
}

export const HeadphonesWarningModal: React.FC<HeadphonesWarningModalProps> = ({
  show,
  onClose,
  onAccept,
}) => {
  const [check, setCheck] = useState(false);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex flex-col items-center self-stretch gap-4 w-[800px]">
        <img
          src="/headphones.svg"
          width={40}
          height={40}
          alt="headphones-icon"
        />
        <Modal.Title>Please don’t use headphones</Modal.Title>
        <Modal.Subtitle>
          We don’t join your calls, but this means that we can only hear all
          speakers if you don’t use headphones
        </Modal.Subtitle>
      </div>

      <div className="flex flex-col items-center self-stretch gap-4 mt-4">
        <Button
          className="w-fit"
          onClick={() => {
            onAccept({ showAgain: !check });
          }}
        >
          Got it - start recording
        </Button>
        <label className="grid gap-2">
          <Checkbox
            checked={check}
            onChange={e => setCheck(e.target.checked)}
            id="headphone-warning"
            label="Don't show this again"
            size={16}
          />
        </label>
      </div>
    </Modal>
  );
};
