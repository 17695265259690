import React from 'react';
import { Check, ChevronUp, X } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { KeyDriverTag } from '@/components/CallDetailsPage/Playbook/KeyDriverTag';
import { PlaybookItemTitle } from '@/components/CallDetailsPage/Playbook/PlaybookItemTitle';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { OnboardingTooltip } from '@/components/Onboarding/OnboardingTooltip/OnboardingTooltip';
import { KEYDRIVER_ITEMS } from '@/constants';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';

import { SeeHowFeedbackCard } from '../SeeHow/SeeHowFeedbackCard';
import { QuestionMarkIcon } from './icons/QuestionMark.icon';

export enum PlaybookItemAnswer {
  False = 'false',
  True = 'true',
  Unsure = 'unsure',
}

interface PlaybookCardProps {
  answer: PlaybookItemAnswer | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  callId: string;
  isTooltipCard?: boolean;
}

export const PlaybookCard: React.FC<PlaybookCardProps> = ({
  item,
  answer,
  callId,
  isTooltipCard = false,
}: PlaybookCardProps) => {
  const seeHowResult = item.seeHowResult;
  const { navigateToTranscript } = useNavigateToTranscript();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTimestampClick = (e: any) => {
    e.stopPropagation();
    navigateToTranscript({
      callId,
      timestamp: item.seeHowResult?.userPhraseTimestamp,
      text: item.seeHowResult?.userPhraseSuggestion,
    });
  };

  return (
    <CollapsibleItem
      wrapperStyles="p-3 rounded-[10px]"
      data-testid={item.slug}
      hideDefaultChevron
      triggerDisabled={item.result === 'true'}
      openByDefault={isTooltipCard}
      title={({ isOpen }) => (
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center gap-2">
            {answer ? (
              <PlaybookCardIcon answer={answer} />
            ) : (
              <PlaybookCardSpinner />
            )}
            <div className="flex justify-start items-center gap-1">
              <PlaybookItemTitle item={item} />
              {KEYDRIVER_ITEMS.includes(item.name) && <KeyDriverTag />}
            </div>
          </div>
          {seeHowResult && (
            <div className="w-[72px] flex justify-end">
              {item.result === 'true' ? (
                <span
                  className="text-main-gray-dark text-[10px] tracking-[-0.1px] ml-3 cursor-pointer font-normal hover:text-accent-blue pointer-events-auto"
                  onClick={handleTimestampClick}
                >
                  {formatTimestamp(item.seeHowResult?.userPhraseTimestamp)}
                </span>
              ) : (
                <div className="flex items-center gap-0">
                  <Button
                    variant="link"
                    className={cn(
                      'text-xs font-semibold pointer-events-auto relative',
                    )}
                  >
                    {isOpen ? (
                      <ChevronUp size={14} strokeWidth={2.5} />
                    ) : (
                      <span className="text-nowrap">See how</span>
                    )}
                  </Button>
                  {isTooltipCard && (
                    <OnboardingTooltip
                      placement="right-start"
                      tooltipId="see-how-playbook"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    >
      {seeHowResult && (
        <div>
          <div className="w-full border-t my-4" />
          <div className="text-sm mb-2">
            {item.seeHowResult?.generalFeedback &&
              item.seeHowResult?.generalFeedback?.trim() !== 'N/A' &&
              item.seeHowResult?.generalFeedback}
          </div>
          <div className="flex flex-col gap-2">
            <SeeHowFeedbackCard
              callId={callId}
              variant="userQuote"
              quote={item.seeHowResult?.userPhrase}
              timestamp={item.seeHowResult?.userPhraseTimestamp}
            />
            <SeeHowFeedbackCard
              callId={callId}
              variant="suggestion"
              quote={item.seeHowResult?.userPhraseSuggestion}
            />
          </div>
        </div>
      )}
    </CollapsibleItem>
  );
};

interface PlaybookCardIconProps {
  answer: PlaybookItemAnswer;
}

const PlaybookCardIcon: React.FC<PlaybookCardIconProps> = ({ answer }) => (
  <WithHoverTooltip
    tooltip={
      answer.toLowerCase() === PlaybookItemAnswer.Unsure ? 'Unsure' : undefined
    }
  >
    <div
      className={cn(
        'flex items-start box-content w-4 h-4 p-1 rounded-lg border border-solid',
        answer.toLowerCase() === PlaybookItemAnswer.True &&
          'bg-playbook-green-1 border-playbook-green-2 text-accent-green',
        answer.toLowerCase() === PlaybookItemAnswer.Unsure &&
          'bg-playbook-yellow-1 border-playbook-yellow-2 text-accent-yellow',
        answer.toLowerCase() === PlaybookItemAnswer.False &&
          'bg-playbook-red-1 border-playbook-red-2 text-accent-red',
      )}
    >
      {answer.toLowerCase() === PlaybookItemAnswer.True && <Check size={16} />}
      {answer.toLowerCase() === PlaybookItemAnswer.Unsure && (
        <QuestionMarkIcon height={16} width={16} />
      )}
      {answer.toLowerCase() === PlaybookItemAnswer.False && <X size={16} />}
    </div>
  </WithHoverTooltip>
);

const PlaybookCardSpinner = () => (
  <svg
    className="animate-spin w-7 h-7 flex-shrink-0"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1 9C17.5971 9 18.0047 9.40396 17.955 9.89853C17.8036 11.4084 17.2721 12.8607 16.4038 14.1171C15.3643 15.621 13.8915 16.7728 12.1813 17.419C10.4711 18.0652 8.60462 18.1753 6.83032 17.7346C5.05602 17.2938 3.45802 16.3232 2.249 14.9518C1.03998 13.5804 0.277268 11.8734 0.0623838 10.0578C-0.1525 8.24229 0.19063 6.40435 1.04611 4.78863C1.90159 3.17291 3.22884 1.85604 4.85123 1.01328C6.20647 0.309287 7.71396 -0.0359866 9.23092 0.00295562C9.72781 0.0157115 10.0775 0.470734 10.0152 0.963871C9.9529 1.45701 9.50181 1.80032 9.00475 1.79999C7.85218 1.79923 6.71156 2.07528 5.68098 2.61062C4.38307 3.28483 3.32127 4.33833 2.63689 5.6309C1.9525 6.92348 1.678 8.39383 1.84991 9.84627C2.02181 11.2987 2.63198 12.6644 3.5992 13.7614C4.56642 14.8585 5.84482 15.6351 7.26426 15.9876C8.6837 16.3402 10.1769 16.2522 11.545 15.7352C12.9132 15.2182 14.0914 14.2968 14.923 13.0936C15.5833 12.1383 16.0001 11.0413 16.1438 9.89769C16.2058 9.40451 16.6029 9 17.1 9Z"
      fill="#2C6CF6"
    />
  </svg>
);
