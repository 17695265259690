import dayjs from 'dayjs';
import React from 'react';

import { Button } from '@/components/Button/Button';
import { FeedbackLimitType } from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { useSubscription } from '@/hooks/useSubscription';
import cn from '@/utils/cn';

interface FreemiumRestrictionLimitMessageProps {
  availableLimit: number;
  itemsUsed: number;
  itemsLimit: number;
  className?: string;
  handleUpgradeClick: () => void;
  limitType: FeedbackLimitType;
}

export const FreemiumRestrictionLimitMessage: React.FC<
  FreemiumRestrictionLimitMessageProps
> = ({
  availableLimit,
  itemsLimit,
  itemsUsed,
  handleUpgradeClick,
  className,
  limitType,
}: FreemiumRestrictionLimitMessageProps) => {
  const { currentPeriodEnd } = useSubscription();

  const noLimitLeft = availableLimit <= 0;

  const renderLimitationTitle = (): string | undefined => {
    switch (limitType) {
      case FeedbackLimitType.PLAYBOOK:
        return 'Playbook Analyses';
      case FeedbackLimitType.SALES_COACH:
        return 'AI Coach Analyses';
      case FeedbackLimitType.UPLOADS:
        return 'uploads';
      default:
        return;
    }
  };

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center p-4 gap-1',
        className,
      )}
      style={{
        background: noLimitLeft
          ? 'linear-gradient(0deg, rgba(248, 155, 17, 0.16) 0%, rgba(248, 155, 17, 0.16) 100%), #FFF'
          : 'rgba(185, 199, 215, 0.16)',
      }}
    >
      <div>
        <span
          className={cn(
            'text-dark text-sm font-normal',
            noLimitLeft && 'text-accent-red',
          )}
        >
          {itemsUsed} of {itemsLimit} monthly {renderLimitationTitle()} used.{' '}
        </span>
        <span className="text-main-gray-dark text-sm font-normal">
          Limit resets in {dayjs(currentPeriodEnd).diff(new Date(), 'days')}{' '}
          days
        </span>
      </div>
      <Button onClick={handleUpgradeClick} variant="link" className="p-0">
        Upgrade for unlimited uploads
      </Button>
    </div>
  );
};
