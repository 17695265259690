import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  getSubscription: `${backendURL}/api/subscription`,
};

interface SubscriptionData {
  id: string;
  availableUploadCallsAmount: number | null;
  availableRecordingCallsAmount: number | null;
  availablePlaybookAnalysisCallsAmount: number | null;
  availableSalesCoachAnalysisCallsAmount: number | null;
  callsUploadLimit: number | null;
  callsPlaybookAnalysisLimit: number | null;
  callsRecordingLimit: number | null;
  callsSalesCoachAnalysisLimit: number | null;
  currentPeriodEnd: Date;
  currentPeriodStart: Date;
  isCanceled: boolean;
  isDemoMode: boolean;
  isFree: boolean;
  isExpired: boolean;
  status: string;
  subscriptionPlanName: string;
}

export const getSubscription = async (): Promise<SubscriptionData> => {
  const response = await API(endpoints.getSubscription, {
    method: 'GET',
  });
  return response.json();
};
