/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { homeSaga } from './saga';

export const initialState = {
  isLoggedIn: false,
  loginErrorMessage: null,
  registerErrorMessage: null,
  user: null,
  isImpersonating: false,
  token: null,
  loading: false,
  registerLoading: false,
  message: '',
  calls: [],
  sortedCalls: [],
  callAudio: null,
  callTranscript: null,
  callAnalysis: null,
  selectedCall: null,
  transcriptLoading: false,
  analysisLoading: false,
  analysisType: null,
  slowAnalysisLoading: false,
  savingCall: false,
  analyticsLoading: false,
  analytics: null,
  callQualitativeAnalysis: null,
  callQualitativeAnalysisLoading: false,
  uploadStep: 'file',
  renameCallModalOpen: false,
  deleteCallConfirmationModalOpen: false,
  updateCallDateModalOpen: false,
  renamingCall: null,
  deleteCall: null,
  deleteCallLoading: false,
  directUploadFileLoading: false,
  redirectAfterCallDelete: false,
  fillerWords: [],
  fileUploadError: { hasError: false, errorTitle: '', errorMessage: '' },
  analyticsGrouping: 'meeting',
  insightsChartOption: 'mainSpeakerWordsPerMinute',
  appLoaded: false,
  fetchCallsLoading: false,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    login(state, action) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      state.loggedIn = true;
      state.token = action.payload.token;
      state.username = action.payload.username;
      state.loading = false;
    },
    loginFailed(state, action) {
      state.loginError = action.payload;
      state.loading = false;
    },
    isImpersonating(state, action) {
      state.isImpersonating = true;
    },
    register(state, action) {
      state.registerLoading = true;
    },
    updatePasswordSuccess(state, action) {
      state.loggedIn = true;
      state.token = action.payload.token;
      state.loading = false;
    },
    registerSuccess(state, action) {
      state.loggedIn = true;
      state.token = action.payload.token;
      state.username = action.payload.username;
      state.registerLoading = false;
    },
    registerFailed(state, action) {
      state.registerLoading = false;
      state.registerErrorMessage = action.payload;
    },
    fetchUserDataSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    fetchUserData(state, action) {
      state.loading = true;
    },
    fetchCalls(state, action) {
      state.fetchCallsLoading = true;
    },
    fetchCallsSuccess(state, action) {
      state.fetchCallsLoading = false;
      state.calls = action.payload;
    },
    fetchCallsFailed(state, action) {
      state.fetchCallsLoading = false;
      state.message = action.payload;
    },
    fetchCallAudio(state, action) {
      state.loading = true;
    },
    fetchCallAudioSuccess(state, action) {
      state.loading = false;
      state.callAudio = action.payload;
    },
    fetchCallAudioFailed(state, action) {
      state.loading = false;
      state.message = action.payload;
    },
    fetchCallsStatusFailed(state, action) {
      state.isFetchingCallStatuses = false;
    },
    fetchCallsStatusSuccess(state, action) {
      const calls = action.payload.calls;
      calls.forEach(call => {
        const currentCall = state.calls.find(c => c.id === call.id);
        if (currentCall) {
          currentCall.status = call.status;
        }
      });
      state.isFetchingCallStatuses = false;
    },
    fetchCallTranscript(state, action) {
      state.transcriptLoading = true;
    },
    fetchCallTranscriptSuccess(state, action) {
      state.transcriptLoading = false;
      state.callTranscript = action.payload;
    },
    fetchCallTranscriptFailed(state, action) {
      state.transcriptLoading = false;
      state.message = action.payload;
    },
    fetchCallAnalysis(state, action) {
      state.analysisLoading = true;
    },
    fetchCallAnalysisSuccess(state, action) {
      state.analysisLoading = false;
      state.callAnalysis = action.payload.data;
      state.analysisType = action.payload.analysisType;
    },
    fetchCallAnalysisFailed(state, action) {
      state.analysisLoading = false;
      state.message = action.payload;
    },
    saveCall(state, action) {
      state.savingCall = true;
    },
    saveCallSuccess(state, action) {
      state.savingCall = false;
      state.savedCall = action.payload;
      state.directUploadFileLoading = false;
      state.uploadStep = 'done';
    },
    saveCallFailed(state, action) {
      state.savingCall = false;
      state.message = action.payload;
    },
    setSelectedCall(state, action) {
      state.selectedCall = action.payload;
    },
    updateCall(state, action) {
      state.updatingCall = true;
    },
    updateTranscript(state, action) {
      state.updatingCall = true;
    },
    deleteCall(state, action) {
      state.deleteCallLoading = true;
    },
    disableRedirectAfterCallDelete(state, action) {
      state.redirectAfterCallDelete = false;
    },
    deleteCallSuccess(state, action) {
      state.deleteCall = null;
      state.deleteCallLoading = false;
      state.calls = state.calls.filter(el => el.id !== action.payload);
      state.redirectAfterCallDelete = true;
    },
    deleteCallFailed(state, action) {
      state.deleteCall = null;
      state.deleteCallLoading = false;
      state.message = action.payload;
    },
    setUpdatingCallId(state, action) {
      state.updatingCallId = action.payload.id;
    },
    updateCallSuccess(state, action) {
      state.updatingCall = false;
      let callData = action.payload.data;
      let existingCall = state.calls.find(c => c.id === callData.id);
      if (existingCall) {
        state.calls = state.calls.map(c =>
          c.id === callData.id ? { ...c, ...callData } : c,
        );
      }

      state.message = action.payload;
      state.selectedCall = callData;
      state.updatedCallId = callData.id;
      state.uploadStep = 'done';
    },
    updateCallFailed(state, action) {
      state.updatingCall = false;
      state.message = action.payload;
    },
    checkLogin(state, action) {
      state.loading = true;
      state.appLoaded = false;
    },
    checkLoginComplete(state, action) {
      state.appLoaded = true;
    },
    logout(state, action) {
      state.loggedIn = false;
      state.token = null;
    },
    pollCallSlowAnalysis(state, action) {
      state.slowAnalysisLoading = true;
    },
    pollCallSlowAnalysisSuccess(state, action) {
      state.slowAnalysisLoading = false;
      state.callAnalysis =
        state.selectedCall?.id === action.payload.id
          ? action.payload.data
          : state.callAnalysis;
      state.analysisType = action.payload.analysisType;
    },
    pollCallSlowAnalysisFailed(state, action) {
      state.slowAnalysisLoading = false;
      state.message = action.payload;
    },
    fetchAnalytics(state, action) {
      state.analyticsLoading = true;
    },
    setAnalyticsGrouping(state, action) {
      state.analyticsGrouping = action.payload.grouping;
    },
    setInsightsChartOption(state, action) {
      state.insightsChartOption = action.payload.option;
    },
    fetchAnalyticsSuccess(state, action) {
      state.analyticsLoading = false;
      state.analytics = action.payload.graphData;
      state.fillerWords = action.payload.fillerWordsDetails;
    },
    fetchAnalyticsFailed(state, action) {
      state.analyticsLoading = false;
      state.message = action.payload;
    },
    fetchCallStatuses(state, action) {
      state.isFetchingCallStatuses = true;
    },
    fetchCallQualitativeAnalysis(state, action) {
      state.callQualitativeAnalysisLoading = true;
    },
    fetchCallQualitativeAnalysisSuccess(state, action) {
      state.callQualitativeAnalysisLoading = false;
      state.callQualitativeAnalysis =
        typeof action.payload === 'string'
          ? JSON.parse(action.payload)
          : action.payload;
    },
    fetchCallQualitativeAnalysisFailed(state, action) {
      state.callQualitativeAnalysisLoading = false;
      state.message = action.payload;
    },
    updateQualitativeAnalysis(state, action) {
      state.callQualitativeAnalysisLoading = true;
    },
    updateQualitativeAnalysisSuccess(state, action) {
      state.callQualitativeAnalysisLoading = false;
    },
    updateQualitativeAnalysisFailed(state, action) {
      state.callQualitativeAnalysisLoading = false;
    },
    resetUpdatedCall(state, action) {
      state.updatedCallId = null;
    },
    resetSavedCall(state, action) {
      state.savedCall = null;
    },
    pollTranscriptId(state, action) {
      state.pollingTranscriptId = true;
    },
    pollTranscriptIdSuccess(state, action) {
      state.pollingTranscriptId = false;
      state.speakerQuotes = action.payload.data;
      state.transcriptId = action.payload.transcriptId;
      state.uploadStep = 'speakerSelection';
    },
    pollTranscriptIdFailed(state, action) {
      state.pollingTranscriptId = false;
    },
    resetUploadStep(state, action) {
      state.transcriptId = null;
      state.speakerQuotes = null;
      state.directUploadFileLoading = false;
      state.directUploadFile = null;
      state.uploadStep = 'file';
    },
    directUploadFile(state, action) {
      state.directUploadFileLoading = true;
      state.uploadStep = 'file';
    },
    directUploadFileSuccess(state, action) {
      state.directUploadFile = action.payload;
    },
    directUploadFileFailed(state, action) {
      state.directUploadFileLoading = false;
    },
    setFileUploadError(state, action) {
      state.fileUploadError = {
        hasError: action.payload.hasError,
        errorTitle: action.payload.errorTitle,
        errorMessage: action.payload.errorMessage,
      };
      if (action.payload.hasError) {
        state.uploadStep = 'uploadError';
      }
    },
    openRenameCallModal(state, action) {
      state.renameCallModalOpen = true;
      state.renamingCall = action.payload;
    },
    closeRenameCallModal(state, action) {
      state.renameCallModalOpen = false;
      state.renamingCall = null;
    },
    openDeleteCallConfirmationModal(state, action) {
      state.deleteCallConfirmationModalOpen = true;
      state.deleteCall = action.payload;
    },
    closeDeleteCallConfirmationModal(state, action) {
      state.deleteCallConfirmationModalOpen = false;
      state.deleteCall = null;
    },
    getCallDateAndType(state, action) {
      state.uploadStep = 'dateSelection';
    },
    setuploadController(state, action) {
      state.uploadController = action.payload;
    },
    toggleMode() {},
    toggleModeSuccess(state, action) {
      state.user.mode = action.payload.mode;
    },
    toggleModeFailed(state, action) {
      state.message = action.payload;
    },
    openUpdateCallDateModal(state, action) {
      state.activeRescheduleCall = action.payload;
      state.updateCallDateModalOpen = true;
    },
    closeUpdateCallDateModal(state, action) {
      state.updateCallDateModalOpen = false;
      state.activeRescheduleCall = null;
    },
    setSortedCalls(state, action) {
      state.sortedCalls = action.payload;
    },
    resetLoginError(state, action) {
      state.loginError = null;
    },
    resetRegisterErrorMessage(state, action) {
      state.registerErrorMessage = null;
    },
    sendSlackAlert(state, action) {},
  },
});

export const { actions: homeActions } = homeSlice;

export const useHomeSlice = () => {
  useInjectReducer({ key: homeSlice.name, reducer: homeSlice.reducer });
  useInjectSaga({ key: homeSlice.name, saga: homeSaga });
  return { actions: homeSlice.actions };
};
