import { useMutation } from '@tanstack/react-query';

import { sendPasswordResetEmail as sendPasswordResetEmailAPICall } from '@/api/auth/reset-password/sendPasswordResetEmail';

export const useSendPasswordResetEmail = () => {
  const { mutateAsync: sendPasswordResetEmail, ...rest } = useMutation({
    mutationFn: (email: string) => sendPasswordResetEmailAPICall({ email }),
  });

  return { sendPasswordResetEmail, ...rest };
};
