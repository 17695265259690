import React from 'react';
import { Edit2, List } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { DemoPlaybookItemsResults } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import {
  FeedbackLimitType,
  useFeedbackLimitModalStore,
} from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { useReanalyzeCallPlaybook } from '@/components/CallDetailsPage/hooks/useReanalyzeCallPlaybook';
import { ItsSalesCallButton } from '@/components/CallDetailsPage/ItsSalesCallButton';
import {
  PlaybookCard,
  PlaybookItemAnswer,
} from '@/components/CallDetailsPage/Playbook/PlaybookCard';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { FreemiumRestrictionLimitMessage } from '@/components/Shared/Freemium/FreemiumRestrictionLimitMessage';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import { CallFailedCode } from '@/enums/call-failed-code.enum';
import { useSubscription } from '@/hooks/useSubscription';
import { Call, PlaybookItem } from '@/interfaces/call.interface';
import logger from '@/utils/logger';

import { Button } from '../Button/Button';
import { CollapseCallSection } from '../CollapseCallSection/CollapseCallSection';
import { OnboardingTooltip } from '../Onboarding/OnboardingTooltip/OnboardingTooltip';
import { ReanalyzeCallBanner } from './ReanalyzeCallBanner';

interface PlaybookProps {
  call: Call;
}

const Playbook = ({ call }: PlaybookProps) => {
  const { isPending, reanalyzeCallPlaybook } = useReanalyzeCallPlaybook();
  const {
    availablePlaybookAnalysisCallsAmount,
    callsPlaybookAnalysisLimit,
    isFree,
    openStripeCustomerPortal,
  } = useSubscription();
  const { openSubscribeModal } = useSubscribeModalStore();
  const { openFeedbackLimitModal } = useFeedbackLimitModalStore();

  const playbookItems: PlaybookItem[] = call.isDemoCall
    ? DemoPlaybookItemsResults
    : call.playbook?.playbookItems;

  const disabledPlaybookItemIds =
    call.playbook?.playbookItemsDisabledByUser?.map(el => el.playbookItemId) ||
    [];

  const activePlaybookItems = (playbookItems || []).filter(
    (item: PlaybookItem) =>
      call.isDemoCall || !disabledPlaybookItemIds.includes(item.id),
  );

  const sellerEvaluationItems = activePlaybookItems.filter(
    (item: PlaybookItem) => item.category === 'Seller evaluation',
  );

  const activePlaybookStats = call.playbook.playbooksStats.find(
    el => el.playbookId === call.lastReanalyzedPlaybookId,
  );

  const callHasActualPlaybookResults =
    call.lastReanalyzedIndex === call.organization.lastReanalyzedIndex ||
    call.lastReanalyzedPlaybookId === call.organization.activePlaybookId;

  const isCallTooShort = call.failedCode === CallFailedCode.CallTooShort;

  const isSalesCall =
    (call.isSalesCall ||
      call.isSalesCall === null ||
      call.isSalesCall === undefined) &&
    !isCallTooShort;

  const hasPlaybookData =
    (isSalesCall && call.lastReanalyzedPlaybookId !== null) || call.isDemoCall;

  let leftLessThanHalfLimit;
  if (
    callsPlaybookAnalysisLimit &&
    availablePlaybookAnalysisCallsAmount !== null &&
    availablePlaybookAnalysisCallsAmount !== undefined &&
    availablePlaybookAnalysisCallsAmount >= 0
  ) {
    leftLessThanHalfLimit =
      availablePlaybookAnalysisCallsAmount <= callsPlaybookAnalysisLimit / 2;
  }

  const showSubscriptionLimitation =
    availablePlaybookAnalysisCallsAmount !== null &&
    availablePlaybookAnalysisCallsAmount !== undefined &&
    callsPlaybookAnalysisLimit &&
    leftLessThanHalfLimit &&
    !hasPlaybookData;

  const handleUpgradeClick = () => {
    if (isFree) {
      openSubscribeModal();
      return;
    } else {
      openStripeCustomerPortal();
    }
  };

  const handlePlaybookAnalysisClick = async () => {
    if (
      availablePlaybookAnalysisCallsAmount !== null &&
      availablePlaybookAnalysisCallsAmount !== undefined &&
      availablePlaybookAnalysisCallsAmount <= 0
    ) {
      openFeedbackLimitModal(FeedbackLimitType.PLAYBOOK);
      return;
    }

    await reanalyzeCallPlaybook({
      callId: call.id,
    });

    window.location.reload();
  };

  const navigate = useNavigate();

  const isCallRenalyzable =
    call.organization.lastReanalyzedIndex === 1 &&
    activePlaybookStats?.processingItems !== undefined &&
    activePlaybookStats?.processingItems > 0 &&
    isSalesCall;

  logger.debug({ call, isCallTooShort }, 'Playbook Tab');

  const EmptyCallState = () => {
    if (isCallTooShort || !call.isValid) {
      return (
        <div className="mt-4">
          <SummaryBlockEmptyState description="This call is not long enough to analyze with a Sales Playbook framework." />
        </div>
      );
    }

    if (!isSalesCall) {
      return (
        <div>
          <SummaryBlockEmptyState
            title="Sounds like this is not a sales call"
            description="We currently only provide feedback for sales calls"
          />
          <ItsSalesCallButton
            callId={call.id}
            containerStyles="mt-3"
            limitType={FeedbackLimitType.PLAYBOOK}
          />
        </div>
      );
    }

    return (
      <SummaryBlockEmptyState
        title="Sounds like this is not a sales call"
        description="We currently only provide feedback for sales calls"
      />
    );
  };

  const isPlaybookAnalyzing = sellerEvaluationItems.some(x => !x.result);

  const shouldShowReanalyzeCallBanner =
    (!callHasActualPlaybookResults || isCallRenalyzable) &&
    !isCallTooShort &&
    isSalesCall &&
    !isPlaybookAnalyzing;

  return (
    <div>
      {shouldShowReanalyzeCallBanner && <ReanalyzeCallBanner call={call} />}
      <div className="flex flex-col h-full gap-4 pb-5">
        <div className="h-full mt-5">
          <CollapseCallSection
            titleStyles="w-full"
            title={
              <div className="flex flex-row justify-between items-center w-full">
                <span>
                  {call.isDemoCall
                    ? 'Standard Sales Playbook'
                    : call.playbook.name}
                </span>
                <div className="flex items-center">
                  {isSalesCall && (
                    <Button
                      variant="primary-inverted"
                      className="!py-1 !px-3 flex flex-row gap-1 !rounded-[10px] text-xs font-semibold h-fit items-center text-nowrap"
                      onClick={() => {
                        eventTracker.playbookPage.start();
                        navigate('/playbook');
                      }}
                    >
                      <Edit2 className="w-3 h-3 flex-shrink-0" />
                      Edit Playbook
                    </Button>
                  )}

                  {call.isDemoCall && (
                    <OnboardingTooltip
                      placement="right-start"
                      tooltipId="edit-playbook"
                    />
                  )}
                </div>
              </div>
            }
            hideDefaultChevron
            itemsContainerStyles={!isSalesCall ? 'pt-0' : 'pt-1.5'}
            openByDefault
            triggerDisabled
            icon={
              <List width={20} height={20} className="text-main-gray-dark" />
            }
            bottomElement={
              showSubscriptionLimitation && (
                <FreemiumRestrictionLimitMessage
                  className="rounded-b-[16px] mt-4"
                  availableLimit={availablePlaybookAnalysisCallsAmount}
                  itemsLimit={callsPlaybookAnalysisLimit}
                  itemsUsed={
                    callsPlaybookAnalysisLimit -
                    availablePlaybookAnalysisCallsAmount
                  }
                  handleUpgradeClick={handleUpgradeClick}
                  limitType={FeedbackLimitType.PLAYBOOK}
                />
              )
            }
          >
            {(!isSalesCall || !call.isValid) && !call.isDemoCall && (
              <EmptyCallState />
            )}
            {((isSalesCall && hasPlaybookData && call.isValid) ||
              call.isDemoCall) && (
              <div className="flex flex-col gap-1.5 mt-3">
                {sellerEvaluationItems.map((item, index) => (
                  <PlaybookCard
                    item={item}
                    answer={item.result as PlaybookItemAnswer}
                    key={item.name}
                    callId={call.id}
                    isTooltipCard={call.isDemoCall && index === 0}
                  />
                ))}
              </div>
            )}
            {isSalesCall && !hasPlaybookData && call.isValid && (
              <div className="flex flex-col gap-4 justify-center items-start mt-2.5">
                <span className="text-main-gray-dark text-xs">
                  Select ‘Get Playbook Analysis’ below to see how you applied
                  this Sales Playbook.
                </span>
                <Button
                  variant="primary"
                  isLoading={isPending}
                  onClick={handlePlaybookAnalysisClick}
                >
                  Get Playbook Analysis
                </Button>
              </div>
            )}
          </CollapseCallSection>
        </div>
      </div>
    </div>
  );
};

export default Playbook;
