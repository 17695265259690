import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  reanalyzeCallPlaybook: `${backendURL}/api/calls/:callId/reanalyze-playbook`,
};

export type ReanalyzeCallPlaybookParams = {
  callId: string;
};

export const reanalyzeCallPlaybook = async (
  params: ReanalyzeCallPlaybookParams,
) =>
  await API(endpoints.reanalyzeCallPlaybook.replace(':callId', params.callId), {
    method: 'POST',
  });
