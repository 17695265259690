import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { ChevronUp, Square } from 'react-feather';
import { useShallow } from 'zustand/react/shallow';

import { eventTracker } from '@/amplitude/eventTracker';
import { CalendarRecordingModal } from '@/components/Recording/CalendarRecordingModal';
import { RecordButtonOptions } from '@/components/Shared/RecordButtonOptions';
import { useModal } from '@/hooks/useModal';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';
import { useSubscription } from '@/hooks/useSubscription';
import cn from '@/utils/cn';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

import { Button } from '../Button/Button';
import { WithHoverTooltip } from '../HoverTooltip/WithHoverTooltip';
import { RecordIcon } from './icons/RecordIcon';

interface RecordButtonProps {
  title?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  withRecordingDropdown?: boolean;
}

export const RecordButton: React.FC<RecordButtonProps> = ({
  title = 'Record',
  variant = 'primary',
  className,
  withRecordingDropdown = false,
}) => {
  const {
    isOpen: isOpenCalendarIntegrationModal,
    open: openCalendarIntegrationModal,
    close: closeCalendarIntegrationModal,
  } = useModal();

  const { recordingSettingsData } = useRecordingSettings();

  const {
    isRecording,
    isRecordingRequested,
    requestRecordingStop,
    requestRecordingStart,
  } = useCallRecordingStore(
    useShallow(state => ({
      isRecording: state.isRecording,
      isRecordingRequested: state.isRecordingRequested,
      requestRecordingStart: state.requestRecordingStart,
      requestRecordingStop: state.requestRecordingStop,
    })),
  );

  const { isGeneralBotFlowActive, requestBotRecordingStop } =
    useBotRecordingStore(
      useShallow(state => ({
        isGeneralBotFlowActive: state.isGeneralBotFlowActive,
        requestBotRecordingStop: state.requestRecordingStop,
      })),
    );

  const { canCreateNewCalls, isPending: subscriptionLoading } =
    useSubscription();

  // const handleClickProd = () => {
  //   if (isRecording) {
  //     requestRecordingStop();
  //     return;
  //   }
  //
  //   requestRecordingStart();
  // };

  const handleClick = () => {
    if (isRecording) {
      requestRecordingStop();
      eventTracker.record.complete();
    } else if (isGeneralBotFlowActive) {
      requestBotRecordingStop();
      eventTracker.record.complete();
    } else if (recordingSettingsData?.botJoinMeetings === true) {
      openCalendarIntegrationModal();
    } else {
      eventTracker.record.start();
      requestRecordingStart();
    }
  };

  const isLoading =
    subscriptionLoading ||
    (isRecordingRequested && !isRecording) ||
    !recordingSettingsData;

  const isActiveRecording = isRecording || isGeneralBotFlowActive;

  return (
    <>
      <CalendarRecordingModal
        show={isOpenCalendarIntegrationModal}
        onClose={closeCalendarIntegrationModal}
      />
      <WithHoverTooltip
        tooltip={
          canCreateNewCalls
            ? undefined
            : 'Upgrade your plan to record new calls'
        }
        placement="bottom-end"
      >
        <div className="flex flex-row">
          <Button
            variant={isActiveRecording ? 'danger' : variant}
            isLoading={isLoading}
            onClick={handleClick}
            className={
              withRecordingDropdown && !isActiveRecording
                ? cn('rounded-none rounded-l-lg', className)
                : className
            }
            disabled={!canCreateNewCalls}
          >
            {isActiveRecording ? <Square size={16} /> : <RecordIcon />}
            <span className="font-semibold text-[13px]">
              {isActiveRecording ? 'Stop Recording' : title}
            </span>
          </Button>
          {withRecordingDropdown && !isActiveRecording && (
            <Button
              variant="primary"
              disabled={!canCreateNewCalls}
              className={cn(
                'px-0 rounded-r-lg rounded-l-none',
                canCreateNewCalls &&
                  'border-l-[1px] border-accent-dark-blue hover:bg-accent-dark-blue cursor-pointer',
              )}
            >
              <div className="transition-all duration-300 h-full flex items-center justify-center">
                <Menu
                  as="div"
                  className="relative inline-block text-left rounded-r-lg"
                >
                  <Menu.Button
                    className={cn(
                      'flex items-center justify-center px-2 py-2.5',
                    )}
                  >
                    {({ open }) => (
                      <ChevronUp
                        size={14}
                        className={cn(
                          'transition ease-in-out',
                          open ? '' : 'rotate-180',
                          canCreateNewCalls
                            ? 'text-white'
                            : 'text-main-gray-dark text-opacity-40',
                        )}
                      />
                    )}
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-100 right-0">
                      <Menu.Item>
                        <RecordButtonOptions />
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </Button>
          )}
        </div>
      </WithHoverTooltip>
    </>
  );
};
