import { amplitudeService } from './amplitude.service';

export const eventTracker = {
  auth: {
    loginComplete(params: { email: string }) {
      amplitudeService.trackEvent('Login_Complete', params);
    },
    signUpComplete(params: {
      email: string;
      firstName: string;
      lastName: string;
    }) {
      amplitudeService.trackEvent('Sign_Up_Complete', params);
    },
  },
  insights: {},
  calls: {},
  callDetails: {
    changeSellerClick(params: { callId: string }) {
      amplitudeService.trackEvent('Seller_Click', params);
    },
    changeSellerApply(params: { callId: string; speaker: string }) {
      amplitudeService.trackEvent('Seller_Apply', params);
    },
  },
  record: {
    start() {
      amplitudeService.trackEvent('Record_Start');
    },
    complete() {
      amplitudeService.trackEvent('Record_Stop');
    },
  },
  upload: {
    start(params: { fileName: string; duration: number }) {
      amplitudeService.trackEvent('Upload_Start', params);
    },
    complete(params: { callId: string }) {
      amplitudeService.trackEvent('Upload_Complete', params);
    },
  },
  playbookPage: {
    start() {
      amplitudeService.trackEvent('Playbook_Click');
    },
    complete() {
      amplitudeService.trackEvent('Playbook_Complete');
    },
  },
  pageTimeSpent(pageName: string, params: { path: string; timeSpent: number }) {
    amplitudeService.trackEvent(`Page_Time_Spent-${pageName}`, {
      page: pageName,
      ...params,
    });
  },
} as const;
