import { Link, LinkProps } from 'react-router-dom';

import cn from '@/utils/cn';

const LogoContent: React.FC = () => (
  <>
    <img src="/logo-white.svg" alt="logo" />
    <span className="text-dark text-base font-semibold">SilkChart</span>
  </>
);

interface LogoProps {
  to?: LinkProps['to'];
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ to, className }) => {
  if (!to) {
    return (
      <div className={cn('flex items-center gap-2', className)}>
        <LogoContent />
      </div>
    );
  }

  return (
    <Link to={to} className={cn('flex items-center gap-2', className)}>
      <LogoContent />
    </Link>
  );
};
