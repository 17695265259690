import dayjs from 'dayjs';

import { Call } from '@/interfaces/call.interface';

export const canDeleteCall = (call: Pick<Call, 'status' | 'queuedAt'>) => {
  if (['failed', 'done'].includes(call.status)) {
    return true;
  }
  if (call.status === 'processing') {
    return dayjs().subtract(30, 'minutes').isAfter(dayjs(call.queuedAt));
  }
  return false;
};
