import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CallsList } from '@/components/CallsList/CallsList';
import { usePageTracking } from '@/hooks/usePageTracking';

import CallDetailsPage from '../CallDetailsPage';
import { selectSortedCalls } from '../HomePage/slice/selectors';

const CallsTabPage = () => {
  let { id } = useParams();
  const sortedCalls = useSelector(selectSortedCalls);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id && sortedCalls.length > 0) {
      const filteredCalls = sortedCalls.filter(
        call => call.status === 'done' || call.status === 'botRecording',
      );
      let activeCall = sortedCalls[0];

      if (filteredCalls.length > 0) {
        activeCall = filteredCalls[0];
      }

      navigate(`/calls/${activeCall.id}`);
    }
  }, [id, sortedCalls]);

  const tabSearchParam = searchParams.get('tab');
  const tab = tabSearchParam
    ? tabSearchParam.charAt(0).toUpperCase() + tabSearchParam.slice(1)
    : '';
  usePageTracking('Call' + tab);

  return (
    <div className="flex h-[calc(100vh-69px)]">
      <CallsList />
      <CallDetailsPage />
    </div>
  );
};

export default CallsTabPage;
