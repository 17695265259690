import { HelpCircle } from 'react-feather';

import { Button } from '@/components/Button/Button';
import {
  FeedbackLimitType,
  useFeedbackLimitModalStore,
} from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { useForceCallAnalyze } from '@/components/CallDetailsPage/hooks/useForceCallAnalyze';
import { useSubscription } from '@/hooks/useSubscription';

interface ItsSalesCallButtonProps {
  callId: string;
  limitType?: FeedbackLimitType;
  containerStyles?: string;
}

export const ItsSalesCallButton: React.FC<ItsSalesCallButtonProps> = ({
  callId,
  containerStyles,
  limitType,
}: ItsSalesCallButtonProps) => {
  const { isPending, forceCallAnalyze } = useForceCallAnalyze();
  const {
    availableSalesCoachAnalysisCallsAmount,
    availablePlaybookAnalysisCallsAmount,
  } = useSubscription();
  const { openFeedbackLimitModal } = useFeedbackLimitModalStore();

  const handleClick = async () => {
    const analyzePlaybook =
      availablePlaybookAnalysisCallsAmount === undefined ||
      availablePlaybookAnalysisCallsAmount === null
        ? true
        : availablePlaybookAnalysisCallsAmount > 0;

    const analyzeSalesCoachFeedback =
      availableSalesCoachAnalysisCallsAmount === undefined ||
      availableSalesCoachAnalysisCallsAmount === null
        ? true
        : availableSalesCoachAnalysisCallsAmount > 0;

    if (!analyzePlaybook && !analyzeSalesCoachFeedback) {
      openFeedbackLimitModal(limitType);
      return;
    }

    await forceCallAnalyze({
      callId,
      analyzePlaybook,
      analyzeSalesCoachFeedback,
    });

    window.location.reload();
  };

  return (
    <div className={containerStyles}>
      <Button
        variant="secondary-inverted"
        onClick={handleClick}
        isLoading={isPending}
      >
        <HelpCircle size={16} />
        {`It's actually a sales call`}
      </Button>
    </div>
  );
};
