import { Trash } from 'react-feather';
import { useDispatch } from 'react-redux';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';

import { useHomeSlice } from '../../pages/HomePage/slice';

interface DeleteCallModalProps {
  call: any;
  show: boolean;
  onClose: () => void;
}

export const DeleteCallModal: React.FC<DeleteCallModalProps> = ({
  call,
  show,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { actions: homeActions } = useHomeSlice();

  const deleteCall = () => {
    dispatch(
      homeActions.deleteCall({
        id: call.id,
      }),
    );

    onClose();
  };

  return (
    <Modal show={show} onClose={onClose} showCloseButton={true}>
      <Modal.Title>Are you sure you want to delete this call?</Modal.Title>
      <p className="text-main-gray-dark text-xs font-normal mt-1">
        <span className="font-bold">“{call.name}”</span> will be deleted forever
        and cannot be restored
      </p>
      <div className="flex gap-2 justify-end mt-4">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteCall}>
          <Trash className="text-white" size={16} />
          Delete Call
        </Button>
      </div>
    </Modal>
  );
};
