import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { teamsMock } from '@/components/LeadershipTeamPage/mock';
import { selectUser } from '@/pages/HomePage/slice/selectors';
import { selectTimeRangeFilter } from '@/pages/LeadershipPage/slice/selectors';
import { TeamPerformanceHelper } from '@/pages/LeadershipTeam/helpers/teamPerformanceHelper';
import { useLeadershipTeamSlice } from '@/pages/LeadershipTeam/slice';
import {
  selectActivePlaybookMetrics,
  selectShowTeamTableColumnOptions,
  selectTeamPerformance,
  selectTeamTableLoading,
} from '@/pages/LeadershipTeam/slice/selectors';

import LoadMoreButton from '../LeadershipPage/shared/LoadMoreButton';
import {
  teamPerformanceDefaultColumns,
  teamPerformanceDynamicColumns,
} from './TeamPerformanceColumns';

const TeamPerformanceTable = () => {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const { actions } = useLeadershipTeamSlice();

  const currentTimeRangeFilter = useSelector(selectTimeRangeFilter);
  const teamPerformance = useSelector(selectTeamPerformance);
  const activePlaybookMetrics = useSelector(selectActivePlaybookMetrics);
  const teamTableLoading = useSelector(selectTeamTableLoading);
  const showTeamTableColumnOptions = useSelector(
    selectShowTeamTableColumnOptions,
  );
  const useMock = user.email.includes('demo.com');

  const [data, setData] = useState(() => []);
  const DEFAULT_PAGINATION_SIZE = 5;
  const [pagination, setPagination] = useState({
    iteration: 1,
    size: DEFAULT_PAGINATION_SIZE,
  });
  const [sorting, setSorting] = React.useState([
    { id: 'salesPlaybookRating', desc: true },
  ]);

  useEffect(() => {
    if (!showTeamTableColumnOptions.length) {
      dispatch(actions.fetchTeamColumnsOptions());
    }
  }, [showTeamTableColumnOptions]);

  useEffect(() => {
    const sortOptions = sorting[0];
    const sortBy = TeamPerformanceHelper.columnIdToSortBy[sortOptions?.id];
    const sortDirection = sortOptions?.desc === true ? 'DESC' : 'ASC';

    dispatch(
      actions.fetchTeamPerformance({
        sortBy,
        sortDirection,
        timeFilter: currentTimeRangeFilter,
        page: 0,
        size: pagination.size,
      }),
    );
  }, [currentTimeRangeFilter, sorting, pagination]);

  useEffect(() => {
    setPagination({ iteration: 1, size: DEFAULT_PAGINATION_SIZE });
  }, [currentTimeRangeFilter]);

  useEffect(() => {
    if (!teamPerformance) return;

    let teamPerformanceWithInfo;

    if (useMock) {
      teamPerformanceWithInfo = TeamPerformanceHelper.setMembersMetricsInfo(
        teamsMock.slice(0, pagination.size),
        activePlaybookMetrics,
      );
    } else {
      teamPerformanceWithInfo = TeamPerformanceHelper.setMembersMetricsInfo(
        teamPerformance,
        activePlaybookMetrics,
      );
    }

    const tableData = teamPerformanceWithInfo.map(member => ({
      ...member,
      id: member.organizationUserId,
      fullName: `${member.firstName} ${member.lastName}`,
      averageSpeechPace: member.averageSpeechPace,
      averageTalkPercentage: member.averageTalkPercentage,
      averageLongestMonologue: member.averageLongestMonologue,
      averageQuestionsAsked: member.averageQuestionsAsked,
      averageFillerWords: member.averageFillerWords,
      playbookFulfilledPercentage: member.playbookFulfilledPercentage,
    }));

    setData(tableData);
  }, [teamPerformance]);

  const nextPage = () => {
    const newIteration = pagination.iteration + 1;
    const newSize = newIteration * DEFAULT_PAGINATION_SIZE;

    setPagination({ iteration: newIteration, size: newSize });
  };

  const columnHelper = createColumnHelper();

  const defaultColumns = useMemo(
    () => teamPerformanceDefaultColumns(columnHelper, user),
    [],
  );

  const dynamicColumns = useMemo(
    () =>
      teamPerformanceDynamicColumns(
        columnHelper,
        activePlaybookMetrics,
        showTeamTableColumnOptions,
        useMock,
      ),
    [showTeamTableColumnOptions, activePlaybookMetrics],
  );

  const table = useReactTable({
    columns: [...defaultColumns, ...dynamicColumns],
    data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  const activeMetricsColumnsWidth = activePlaybookMetrics.reduce(
    (accum, item) => {
      const column = showTeamTableColumnOptions.find(el => el.key === item.key);

      if (column && column.show) {
        const styles =
          TeamPerformanceHelper.defaultStylesForActivePlaybookMetricColumn(
            item,
          );

        const width = styles
          ? Number(styles.header.minWidth.replace(/[^0-9]/g, ''))
          : 167;
        const padding = 32;

        return accum + width + padding;
      }

      return accum;
    },
    0,
  );
  const tableWidth = 1276 + activeMetricsColumnsWidth;

  return (
    <div className="relative">
      <div className="overflow-x-visible overflow-y-hidden pb-2 team-table">
        <div className="pl-6" style={{ minWidth: tableWidth }}>
          {table.getHeaderGroups().map(headerGroup => (
            <div
              className="flex flex-row"
              style={{ minWidth: tableWidth }}
              key={headerGroup.id}
            >
              {headerGroup.headers.map(header =>
                flexRender(header.column.columnDef.header, header.getContext()),
              )}
            </div>
          ))}
        </div>
        <div
          className="flex flex-col gap-2 mt-4"
          style={{ minWidth: tableWidth + 24 }}
        >
          {table.getRowModel().rows.map(row => (
            <div
              className="flex flex-row pl-6 pt-3 pb-3 border border-1 border-[#ECECEC] rounded-[20px] shadow-sm"
              style={{ minWidth: tableWidth + 24 }}
              key={row.id}
            >
              {row
                .getVisibleCells()
                .map(cell =>
                  flexRender(cell.column.columnDef.cell, cell.getContext()),
                )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center h-[36px] mt-6">
        <LoadMoreButton
          onClick={() => nextPage()}
          disabled={pagination.size > data?.length}
          isLoading={teamTableLoading}
        />
      </div>
    </div>
  );
};

export default TeamPerformanceTable;
