export const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
export const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;
export const FOREVER = ONE_YEAR * 100;

/**
 * Get a cookie by name
 */
export function getCookie(name: string): string | null {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
}

/**
 * Set a cookie
 */
export function setCookie(name: string, value: string, durationMs: number) {
  const expires = new Date();
  expires.setTime(expires.getTime() + durationMs);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

/**
 * Delete a cookie
 */
export function deleteCookie(name: string) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}
