import { useMutation } from '@tanstack/react-query';

import { reanalyzeCallPlaybook as reanalyzeCallPlaybookAPI } from '@/api/call/reanalyzeCallPlaybook';

export const useReanalyzeCallPlaybook = () => {
  const { mutateAsync: reanalyzeCallPlaybook, ...rest } = useMutation({
    mutationFn: reanalyzeCallPlaybookAPI,
  });

  return { reanalyzeCallPlaybook, ...rest };
};
