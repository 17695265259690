import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';

export const usePageTracking = (pageName: string) => {
  const location = useLocation();
  const lastPageVisitTime = useRef<Date | null>(null);

  useEffect(() => {
    const trackPageTime = () => {
      const currentPage = location.pathname + location.search;
      const now = new Date();

      if (lastPageVisitTime.current) {
        const timeSpent =
          (now.getTime() - lastPageVisitTime.current.getTime()) / 1000; // Time in seconds

        // Track only if time spent is greater than 200ms seconds to avoid tracking redirects and quick unintended page visits
        if (timeSpent > 0.2) {
          eventTracker.pageTimeSpent(pageName, {
            path: currentPage,
            timeSpent,
          });
        }
      }

      lastPageVisitTime.current = now; // Update the last visit time
    };

    trackPageTime();

    // Track time spent on page when user leaves the page or refreshes
    window.onbeforeunload = () => trackPageTime();

    // Cleanup function to ensure time is tracked when leaving the page
    return () => trackPageTime();
  }, [location, pageName]);
};
