import { Tab } from '@headlessui/react';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { CallSummaryDetails } from '@/components/CallDetailsPage/Summary/CallSummaryDetails';
import { selectCall } from '@/pages/CallDetailsPage/slice/selectors';
import cn from '@/utils/cn';

import { OnboardingTooltip } from '../Onboarding/OnboardingTooltip/OnboardingTooltip';
import { OnboardingTooltipId } from '../Onboarding/OnboardingTooltip/useOnboardingTooltip';
import { CallAssets } from './Assets/CallAssets';
import { DemoCallSummaryDetails } from './DemoCall/DemoCallSummaryDetails';
import { Feedback } from './Feedback/Feedback';
import Playbook from './Playbook';
import { useCallDetailsStore } from './useCallDetailsStore';

export const CALL_TAB_KEYS = {
  summary: 'summary',
  playbook: 'playbook',
  feedback: 'feedback',
  transcript: 'transcript',
};

export const DoneState: React.FC = () => {
  const call = useSelector(selectCall);
  const [searchParams, setSearchParams] = useSearchParams();

  const showQuestionsTab =
    dayjs(call?.createdAt).isAfter(dayjs('2024-03-17')) ||
    Number(call?.topQuestions?.topQuestionsCount) > 0 ||
    call.isDemoCall;

  const { tabIndex, setTabIndex } = useCallDetailsStore();

  const tabsData = useMemo(() => {
    const baseTabs: Array<{
      key: string;
      panel: React.ReactNode;
      tabText: string;
      loadEvent?: (data: { callId: string }) => void;
      onboardingTooltipId?: OnboardingTooltipId | undefined;
      onboardingTooltipAction?: () => void;
      beforeShowingOnboardingTooltip?: () => void;
    }> = [
      {
        key: CALL_TAB_KEYS.playbook,
        panel: <Playbook call={call} />,
        tabText: 'Playbook',
        onboardingTooltipId: 'playbook',
        beforeShowingOnboardingTooltip: () => setTabIndex((tabIndex ?? 0) + 1),
      },
      {
        key: CALL_TAB_KEYS.feedback,
        panel: <Feedback call={call} />,
        tabText: 'Feedback',
      },
    ];
    if (showQuestionsTab) {
      baseTabs.unshift({
        key: CALL_TAB_KEYS.summary,
        panel: call.isDemoCall ? (
          <DemoCallSummaryDetails callId={call.id} />
        ) : (
          <CallSummaryDetails callId={call.id} />
        ),
        tabText: 'Summary',
        onboardingTooltipAction() {
          setTabIndex((tabIndex ?? 0) + 1);
        },
      });
    }

    return baseTabs;
  }, [call, showQuestionsTab]);

  const tabSearchParam = searchParams.get('tab');

  const setTabFromSearchParams = () => {
    const tabIndex = tabsData.findIndex(tab => tab.key === tabSearchParam);
    if (tabIndex === -1) {
      return;
    }

    setTabIndex(tabIndex);
  };

  useEffect(() => {
    setTabFromSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSearchParam]);

  const trackTabOpenEvent = (tabIndex: number) => {
    const tab = tabsData[tabIndex];
    tab.loadEvent?.({ callId: call.id });
  };

  const handleOnChange = (index: number) => {
    trackTabOpenEvent(index);
    setTabIndex(index);

    const tab = tabsData[index];
    if (!tab) {
      return;
    }
    setSearchParams(`tab=${tab.key}`);
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <Tab.Group onChange={handleOnChange} selectedIndex={tabIndex}>
          <Tab.List className="flex gap-6 items-center border-b border-b-main-gray-main">
            {tabsData.map(
              ({
                key,
                tabText,
                onboardingTooltipId,
                onboardingTooltipAction,
                beforeShowingOnboardingTooltip,
              }) => (
                <div key={key} className="relative">
                  <Tab
                    className={({ selected }) =>
                      cn(
                        'flex flex-col items-center focus:ring-0 focus:outline-none',
                        selected &&
                          "after:content-[''] after:inline-block after:bg-accent-blue after:h-1 after:-mt-1 after:w-full after:rounded-full",
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={cn(
                            'text-sm py-[12px]',
                            selected
                              ? 'text-dark font-semibold'
                              : 'text-main-gray-dark font-medium',
                          )}
                        >
                          {tabText}
                        </span>
                      </>
                    )}
                  </Tab>
                  {onboardingTooltipId && (
                    <OnboardingTooltip
                      tooltipId={onboardingTooltipId}
                      placement="bottom-start"
                      onActionButtonClick={() => {
                        onboardingTooltipAction?.();
                      }}
                      beforeShowTooltip={() => {
                        beforeShowingOnboardingTooltip?.();
                      }}
                    />
                  )}
                </div>
              ),
            )}
          </Tab.List>
          <Tab.Panels>
            {tabsData.map(({ key, panel }) => (
              <Tab.Panel key={key} unmount={false}>
                {panel}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="ml-6 flex-1">
        <CallAssets call={call} />
      </div>
    </div>
  );
};
