import dayjs from 'dayjs';

export const mock = {
  data: {
    averageSpeechPace: [120, 170, 105, 170, 113, 145, 111],
    averageFillerWords: [34, 24, 44, 23, 3, 2, 7],
    averageQuestionsAsked: [99, 88, 23, 34, 113, 145, 111],
    averageLongestMonologue: [6, 170, 23, 170, 113, 145, 111],
    averageTalkPercentage: [3, 23, 78, 80, 78, 56, 23],
  },
  labels: [
    dayjs().subtract(6, 'days').format('MMM D'),
    dayjs().subtract(5, 'days').format('MMM D'),
    dayjs().subtract(4, 'days').format('MMM D'),
    dayjs().subtract(3, 'days').format('MMM D'),
    dayjs().subtract(2, 'days').format('MMM D'),
    dayjs().subtract(1, 'days').format('MMM D'),
    dayjs().format('MMM D'),
  ],
  performance: {
    averageSpeechPace: '120',
    averageFillerWords: '20',
    averageQuestionsAsked: '12',
    averageLongestMonologue: '183',
    averageTalkPercentage: '30',
    averageAhWord: '0',
    averageLikeWord: '24',
    averageUhWord: '21',
    averageUmWord: '47',
    playbookFulfilledPercentage: '87',
  },
};
