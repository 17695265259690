import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useState } from 'react';

import { CallTranscript } from '@/api/call/call-transcript.interface';
import logger from '@/utils/logger';

const backendURL = process.env.REACT_APP_BACKEND_URL;

/**
 * Custom hook to subscribe to Server-Sent Events.
 */
export function useTranscriptSSE(callId: string) {
  const [data, setData] = useState<CallTranscript | null>(null);

  const url = `${backendURL}/api/calls/sse/transcript/${callId}?cacheBuster=${new Date().getTime()}`;

  useEffect(() => {
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    });

    eventSource.onopen = () => {
      logger.info(
        { callId },
        'Connected to the server for real time updates of transcript',
      );
    };

    eventSource.onerror = err => {
      logger.error(err, 'An error occurred while connecting to the server');
    };

    eventSource.onmessage = event => {
      logger.info({ event }, 'Parsed data');
      try {
        if (event.data === null) {
          return;
        }
        const { transcript }: { callId: string; transcript: CallTranscript } =
          JSON.parse(event.data);

        setData(prevData => {
          if (prevData && prevData.utterances) {
            const newUtterance = transcript.utterances[0];
            const lastUtterance =
              prevData.utterances[prevData.utterances.length - 1];

            let updatedUtterances;

            if (lastUtterance && lastUtterance.isFinal === false) {
              // Replace the last utterance with the current one
              updatedUtterances = [
                ...prevData.utterances.slice(0, -1),
                newUtterance,
              ];
            } else {
              // Append the new utterance
              updatedUtterances = [...prevData.utterances, newUtterance];
            }

            // Return a new object with updated utterances
            return {
              ...prevData,
              utterances: updatedUtterances,
            };
          }

          // Initialize data if no previous data exists
          return transcript;
        });
      } catch {
        logger.error('Something went wrong while receiving data');
      }
    };

    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId]);

  return { data };
}
