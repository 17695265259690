import { HelpCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { useGetCallQuestions } from '@/components/CallDetailsPage/Summary/hooks/useGetCallQuestions';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { CallFailedCode } from '@/enums/call-failed-code.enum';
import { Call } from '@/interfaces/call.interface';
import { selectCall } from '@/pages/CallDetailsPage/slice/selectors';

import { CollapseCallSection } from '../../CollapseCallSection/CollapseCallSection';
import { QuestionItem } from './QuestionItem';

export interface QuestionsProps {
  callId: string;
  isCallPublic?: boolean;
}

export const Questions = ({ callId, isCallPublic = false }: QuestionsProps) => {
  const { callQuestions, isLoading } = useGetCallQuestions(
    callId,
    isCallPublic,
  );

  const call: Call = useSelector(selectCall);

  const EmptyCallState = () => {
    if (!call) {
      return (
        <SummaryBlockEmptyState
          description={"We didn't detect prospect questions during this call"}
        />
      );
    }

    if (call.failedCode === CallFailedCode.CallTooShort) {
      return (
        <SummaryBlockEmptyState description="This call is too short to analyze." />
      );
    }
    return (
      <SummaryBlockEmptyState
        description={
          call.isSalesCall
            ? "We didn't detect prospect questions during this call"
            : 'Sounds like this is not a sales call. Questions will appear here for sales calls.'
        }
      />
    );
  };

  return (
    <div className="flex flex-col">
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault
        title="Questions and Objections"
        icon={<HelpCircle size={20} className="text-main-gray-dark" />}
      >
        {isLoading && (
          <div className="flex justify-center">
            <ClipLoader color="rgb(37 99 235)" />
          </div>
        )}
        {callQuestions && callQuestions.length > 0 ? (
          <div
            key="prospects-top-questions"
            className="h-full flex flex-col gap-1"
          >
            {callQuestions.map(({ id, summary, answer, timestamp }) => (
              <QuestionItem
                key={id}
                summary={summary}
                answer={answer}
                timestamp={timestamp}
                callId={callId}
                baseUrl={isCallPublic ? 'share-call' : 'calls'}
              />
            ))}
          </div>
        ) : (
          callQuestions && callQuestions.length === 0 && <EmptyCallState />
        )}
      </CollapseCallSection>
    </div>
  );
};
