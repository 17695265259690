import React from 'react';
import { CheckSquare } from 'react-feather';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { CallNextStepItem } from '@/components/CallDetailsPage/Summary/CallNextStepItem';
import { useGetCallNextSteps } from '@/components/CallDetailsPage/Summary/hooks/useGetCallNextSteps';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { CollapseCallSection } from '@/components/CollapseCallSection/CollapseCallSection';
import { CallFailedCode } from '@/enums/call-failed-code.enum';
import { Call } from '@/interfaces/call.interface';
import { selectCall } from '@/pages/CallDetailsPage/slice/selectors';
import { getExistingResponsibleSpeakersWithColors } from '@/utils/getExistingResponsibleSpeakersWithColors';

export interface CallNextStepsProps {
  callId: string;
  isCallPublic?: boolean;
  readOnly?: boolean;
}

export const CallNextSteps: React.FC<CallNextStepsProps> = ({
  callId,
  isCallPublic = false,
  readOnly = false,
}) => {
  const { callNextSteps, isLoading } = useGetCallNextSteps(
    callId,
    isCallPublic,
  );

  const call: Call = useSelector(selectCall);

  const existingResponsibleSpeakers = callNextSteps
    ? getExistingResponsibleSpeakersWithColors(callNextSteps)
    : {};

  const EmptyCallState = () => {
    if (!call) {
      return (
        <SummaryBlockEmptyState
          description={"We didn't detect next steps during this call"}
        />
      );
    }

    if (call.failedCode === CallFailedCode.CallTooShort) {
      return (
        <SummaryBlockEmptyState description="This call is too short to analyze." />
      );
    }
    return (
      <SummaryBlockEmptyState description="We didn't detect next steps during this call" />
    );
  };

  return (
    <div className="flex flex-col">
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault={true}
        title="Next Steps"
        icon={<CheckSquare size={20} className="text-main-gray-dark" />}
      >
        {isLoading && (
          <div className="flex justify-center">
            <ClipLoader color="rgb(37 99 235)" />
          </div>
        )}
        {callNextSteps && callNextSteps.length > 0 ? (
          <div className="flex flex-col gap-1">
            {callNextSteps.map(nextStep => (
              <CallNextStepItem
                existingResponsibleSpeakers={existingResponsibleSpeakers}
                key={nextStep.id}
                nextStep={nextStep}
                readOnly={readOnly}
                baseUrl={isCallPublic ? 'share-call' : 'calls'}
              />
            ))}
          </div>
        ) : (
          callNextSteps && callNextSteps?.length === 0 && <EmptyCallState />
        )}
      </CollapseCallSection>
    </div>
  );
};
