import { useNavigate } from 'react-router-dom';

import { CALL_TAB_KEYS } from '@/components/CallDetailsPage/DoneState';

export const useNavigateToTranscript = (baseUrl: string = 'calls') => {
  const navigate = useNavigate();

  const navigateToTranscript = (params: {
    callId: string;
    timestamp: string | number;
    text?: string;
  }) => {
    let url = `/${baseUrl}/${params.callId}?tab=${CALL_TAB_KEYS.transcript}&timestamp=${params.timestamp}`;

    if (params.text) {
      url += `&text=${params.text}`;
    }

    navigate(url);
  };

  return { navigateToTranscript };
};
