import { Menu, Transition } from '@headlessui/react';
import { Tab } from '@headlessui/react';
import React, { Fragment } from 'react';
import { BarChart2, ChevronDown, List, LogOut, Users } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { Logo } from '@/components/Shared/Logo';
import { useSubscription } from '@/hooks/useSubscription';

import { useHomeSlice } from '@/pages/HomePage/slice';
import { selectUser } from '@/pages/HomePage/slice/selectors';

/**
 *
 * @param classes
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const LeadershipHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useHomeSlice();
  const { setShowDefaultTrialBanner } = useSubscription();

  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const tabs = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      icon: selected => (
        <BarChart2 size={20} color={selected ? '#2C6CF6' : '#5C6A82'} />
      ),
      onClick: undefined,
    },
    {
      key: 'team',
      title: 'Team',
      icon: selected => (
        <Users size={20} color={selected ? '#2C6CF6' : '#5C6A82'} />
      ),
      onClick: undefined,
    },
  ];

  const handleLogout = () => {
    setShowDefaultTrialBanner(true);
    dispatch(actions.logout());
  };

  const goToEditPlaybookTemplate = () => {
    eventTracker.playbookPage.start();
    navigate('/playbook');
  };

  return (
    <div
      data-testid="header"
      className="relative flex justify-between items-center px-12 pt-4 pb-3 border-b-[1px] border-[#EEE] text-[#717D96] bg-white z-10"
    >
      <Logo to="/dashboard" />
      <div>
        <Tab.List className="flex gap-10 items-center py-2">
          {tabs.map(tab => (
            <Tab
              key={tab.key}
              className={classNames(
                'flex items-center gap-1 focus:ring-0 focus:outline-none',
              )}
              onClick={tab.onClick}
            >
              {({ selected }) => (
                <>
                  {tab.icon(selected)}
                  <span
                    className={classNames(
                      selected ? 'text-[#2C6CF6]' : 'text-[#5C6A82]',
                      'text-sm font-semibold',
                    )}
                  >
                    {tab.title}
                  </span>
                </>
              )}
            </Tab>
          ))}
        </Tab.List>
      </div>
      <div className="flex gap-1">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button data-testid="header-menu">
              <div className="flex items-center cursor-pointer rounded-[10px] bg-[#ECECEC] p-2.5">
                {useMock && (
                  <img
                    height={20}
                    width={20}
                    src="/AcmeLogo.svg"
                    className="mr-1"
                  />
                )}
                <span className="text-[#1C1C1C] text-sm font-medium mr-1">
                  {user.organizationName}
                </span>
                <ChevronDown className="h-3 w-3" color="#5C6A82" />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-100 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-2 pt-3 pb-2 gap-1">
                <Menu.Item>
                  <div className="px-2 pb-2 h-auto z-100">
                    <div className="flex flex-col">
                      <div>
                        <span className="text-[8px] text-[#FFF] bg-[#1C1C1C] py-[1px] px-1 rounded-lg">
                          LEADER
                        </span>
                      </div>
                      <span className="text-[#1C1C1C] font-medium mt-2">
                        {user?.username}
                      </span>
                    </div>
                    <div className="text-[10px] leading-3">{user?.email}</div>
                  </div>
                </Menu.Item>
                <div className="my-1 bg-[#F6F6F6] w-100 h-[1px]"></div>
                <Menu.Item>
                  <div className="px-2 h-10">
                    <button
                      className="group flex w-full items-center rounded-md py-2 text-md h-10 gap-1"
                      onClick={goToEditPlaybookTemplate}
                    >
                      <List size={20} color="#1C1C1C" />
                      <span className="text-[#1C1C1C] font-medium">
                        Edit Sales Playbook
                      </span>
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className="px-2 h-10 mt-2">
                    <button
                      className="group flex w-full items-center rounded-md py-2 text-md h-10 gap-1"
                      onClick={handleLogout}
                    >
                      <LogOut size={20} color="#F00" />
                      <span className="text-[#F00] font-medium">Sign out</span>
                    </button>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default LeadershipHeader;
