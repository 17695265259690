import { call, delay, put, race, select, takeLatest } from 'redux-saga/effects';

import { amplitudeService } from '@/amplitude/amplitude.service';
import { eventTracker } from '@/amplitude/eventTracker';
import {
  HIDE_DEFAULT_TRIAL_BANNER_COOKIE,
  SHOW_PAYMENT_SUCCESS_COOKIE,
} from '@/hooks/useSubscription';
import { deleteCookie } from '@/utils/cookie';

import { OnboardingTooltipCookieService } from '@/components/Onboarding/OnboardingTooltip/onboardingTooltipCookieStore';
import { callDetailsActions } from '../../CallDetailsPage/slice';
import { selectCall } from '../../CallDetailsPage/slice/selectors';
import { homeActions as actions } from '.';
import { selectCalls, selectUser } from './selectors';

const backendURL = process.env.REACT_APP_BACKEND_URL;

/**
 *
 * @param action
 */
function* doLogin(action) {
  try {
    const response = yield fetch(backendURL + '/api/auth/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(action.payload),
      // TBD I hav no idea what the intention/expected behaviour on renaming email to verifiedEmail is so Im just "bypassing" auth for now
      body: JSON.stringify({
        ...action.payload,
        email: action.payload?.verifiedEmail,
      }),
    });
    const body = yield response.json();
    if (body.token) {
      yield put(
        actions.loginSuccess({
          token: body.token,
          username: action.payload.email,
        }),
      );
      localStorage.setItem('authToken', body.token);
      amplitudeService.setUserId(body.email || action.payload?.email);
      eventTracker.auth.loginComplete({ email: body.email });
      window.location = '/';
    } else {
      yield put(actions.loginFailed(body.message));
    }
  } catch {
    yield put(actions.loginFailed('Login Failed'));
  }
}

/**
 *
 * @param action
 */
function* doRegister(action) {
  try {
    const response = yield fetch(backendURL + '/api/auth/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const body = yield response.json();
    if (!body.error) {
      window.location = '/';
      OnboardingTooltipCookieService.startOnboarding();
      amplitudeService.setUserId(body.email || action.payload?.email);
      eventTracker.auth.signUpComplete({
        email: body.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      });
      localStorage.setItem('authToken', body.token);
      yield put(actions.registerSuccess(body.token, action.payload.username));
    } else {
      yield put(actions.registerFailed(body.error));
    }
  } catch (err) {
    console.log('registration falied', err);
    let message = err.message;
    console.log('registration falied', message);

    yield put(actions.registerFailed(message));
  }
}

/**
 *
 */
function* doLogout() {
  localStorage.removeItem('authToken');
  deleteCookie(SHOW_PAYMENT_SUCCESS_COOKIE);
  deleteCookie(HIDE_DEFAULT_TRIAL_BANNER_COOKIE);
  OnboardingTooltipCookieService.finishOnboarding();
  amplitudeService.reset();
}

/**
 *
 */
function* doCheckLogin() {
  const savedToken = localStorage.getItem('authToken');
  if (!savedToken) {
    yield put(actions.checkLoginComplete(null));
    yield put(actions.logout());
    return;
  }

  try {
    const response = yield fetch(backendURL + '/api/users/validate', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${savedToken}`,
      },
    });
    const body = yield response.json();

    if (body.statusCode === 401 || body.valid === false) {
      yield put(actions.checkLoginComplete(null));
      yield put(actions.logout());
      window.location.reload();
    }

    if (body.isImpersonating) {
      yield put(actions.isImpersonating());
    }
    yield put(actions.checkLoginComplete(savedToken));
    yield put(actions.loginSuccess(savedToken));
  } catch (err) {
    console.log('check login failed', err);
    yield put(actions.checkLoginComplete(null));
    yield put(actions.logout());
  }
}

/**
 *
 */
function* doFetchCalls() {
  try {
    const response = yield fetch(backendURL + '/api/calls', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    });
    const body = yield response.json();

    yield put(actions.fetchCallsSuccess(body.data));
  } catch (err) {
    console.log('fetch calls failed', err);
    yield put(actions.fetchCallsFailed('Fetch Calls Failed'));
  }
}

/**
 *
 * @param action
 */
function* doFetchCallStatuses(action) {
  try {
    const response = yield fetch(backendURL + '/api/calls/statuses', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ ids: action.payload.callIds }),
    });
    const body = yield response.json();

    const currentCalls = yield select(selectCalls);

    const getUpdatedCalls = () => {
      const result = [];

      for (const updatedCall of body.calls) {
        const currentCall = currentCalls.find(c => c.id === updatedCall.id);
        if (
          currentCall &&
          (currentCall.status !== updatedCall.status ||
            currentCall.botRecordingStatus !== updatedCall.botRecordingStatus ||
            currentCall.failedCode !== updatedCall.failedCode)
        ) {
          result.push(updatedCall);
        }
      }

      return result;
    };

    const updatedCalls = getUpdatedCalls();

    if (updatedCalls.length) {
      yield put(actions.fetchCalls());
    }

    const activeDetailsCall = yield select(selectCall);
    if (updatedCalls.find(c => c.id === activeDetailsCall.id)) {
      yield put(callDetailsActions.fetchCall({ id: activeDetailsCall.id }));
    }

    yield put(actions.fetchCallsStatusSuccess({ calls: body.calls }));
  } catch (err) {
    console.log('fetch calls failed', err);
    yield put(actions.fetchCallsStatusFailed('Fetch Calls Failed'));
  }
}

/**
 *
 * @param action
 */
function* doFetchCallAudio(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload}/audio`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    const body = yield response.json();
    yield put(actions.fetchCallAudioSuccess(body.data));
  } catch (err) {
    console.log('fetch call audio failed', err);
    yield put(actions.fetchCallAudioFailed('Fetch Call Audio Failed'));
  }
}

/**
 *
 * @param action
 */
function* doFetchCallTranscript(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload}/transcript`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    const body = yield response.json();
    yield put(actions.fetchCallTranscriptSuccess(body.data));
  } catch (err) {
    console.log('fetch call transcript failed', err);
    yield put(
      actions.fetchCallTranscriptFailed('Fetch Call Transcript Failed'),
    );
  }
}

/**
 *
 * @param action
 */
function* doFetchCallAnalysis(action) {
  try {
    const response = yield fetch(
      backendURL +
        `/api/calls/${action.payload.id}/analysis?speaker=${action.payload.speaker}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    const body = yield response.json();
    yield put(actions.fetchCallAnalysisSuccess(body));
  } catch (err) {
    console.log('fetch call analysis failed', err);
    yield put(actions.fetchCallAnalysisFailed('Fetch Call Analysis Failed'));
  }
}

/**
 *
 * @param action
 */
function* doSaveCall(action) {
  try {
    const response = yield fetch(backendURL + '/api/calls', {
      method: 'POST',
      headers: {
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const body = yield response.json();
    yield put(actions.saveCallSuccess(body.data));
  } catch (err) {
    console.log('save call failed', err);

    yield put(actions.saveCallFailed('Save Call Failed'));

    const user = yield select(selectUser);

    yield call(doSendSlackAlert, {
      type: 'Save Call Failed - FE side',
      message: 'Save Call Failed - FE side',
      ctx: {
        message: err?.message,
        data: action.payload,
        user,
      },
    });
  }
}

/**
 *
 * @param action
 */
function* doUpdateCall(action) {
  try {
    yield put(actions.setUpdatingCallId({ id: action.payload.id }));
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(action.payload.body),
      },
    );
    const body = yield response.json();
    yield put(actions.updateCallSuccess(body));
    yield put(
      callDetailsActions.updateCallSuccess({
        ...body.data,
      }),
    );
    yield put(actions.setUpdatingCallId({ id: null }));

    if (action.payload.navigate) {
      action.payload.navigate(`/calls/${action.payload.id}`);
    }
  } catch (err) {
    console.log('save call failed', err);
    yield put(actions.updateCallFailed('Save Call Failed'));
    yield put(actions.setUpdatingCallId({ id: null }));
  }
}

/**
 *
 * @param action
 */
function* doUpdateTranscript(action) {
  try {
    yield put(actions.setUpdatingCallId({ id: action.payload.id }));
    yield fetch(backendURL + `/api/calls/${action.payload.id}/transcript`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(action.payload.body),
    });

    yield put(actions.fetchCallTranscript(action.payload.id));
  } catch (err) {
    console.log('update transcript failed', err);
    yield put(actions.updateCallFailed('Save Call Failed'));
    yield put(actions.setUpdatingCallId({ id: null }));
  }
}

/**
 *
 * @param action
 */
function* doDeleteCall(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload.id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    if (response.status === 204) {
      yield put(actions.deleteCallSuccess(action.payload.id));
    }
  } catch (err) {
    console.log('delete call failed', err);
    yield put(actions.deleteCallFailed('Delete Call Failed'));
  }
}

/**
 *
 * @param action
 */
function* doPollCallSlowAnalysis(action) {
  try {
    const response = yield fetch(
      backendURL +
        `/api/calls/${action.payload.id}/savedPrediction?speaker=${action.payload.speaker}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    if (response.ok) {
      const body = yield response.json();
      yield put(
        actions.pollCallSlowAnalysisSuccess({
          data: body.data,
          id: action.payload.id,
        }),
      );
    } else {
      yield put(
        actions.pollCallSlowAnalysisFailed('Fetch Call Analysis Failed'),
      );
    }
  } catch (err) {
    console.log('fetch call analysis failed', err);
    yield put(actions.pollCallSlowAnalysisFailed('Fetch Call Analysis Failed'));
  }
}

/**
 *
 * @param data
 */
function buildDataAnaylticsForMeetings(data) {
  const jargonEntries = data.graphData.filter(a => a.key?.startsWith('JARGON'));
  const jargonAggregates = {};
  jargonEntries.forEach(entry => {
    const value = entry.value << 0;
    const aggregation = entry.aggregation;
    const callId = entry.callid;
    if (!jargonAggregates[callId]) {
      jargonAggregates[callId] = {};
    }
    if (!jargonAggregates[callId][aggregation]) {
      jargonAggregates[callId][aggregation] = {
        count: 0,
        total: 0,
      };
    }
    jargonAggregates[callId][aggregation].count++;
    jargonAggregates[callId][aggregation].total += value;
  });
  const jargonAverages = Object.entries(jargonAggregates)
    .map(([callId, aggregations]) => {
      const averageAggregations = {};
      Object.entries(aggregations).forEach(([aggregation, { total }]) => {
        averageAggregations[aggregation] = {
          key: 'jargon',
          callid: callId,
          aggregation,
          type: 'number',
          value: total,
        };
      });
      return Object.values(averageAggregations);
    })
    .flat()
    .sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  const graphData = data.graphData.concat(jargonAverages);
  graphData.forEach(dataPoint => {
    dataPoint.value = dataPoint.value << 0;
  });
  graphData.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    }
    if (b.date > a.date) {
      return -1;
    }
    return 0;
  });
  return {
    graphData: graphData,
    fillerWordsDetails: data.fillerWordsDetails,
  };
}

/**
 *
 * @param action
 */
function* doFetchAnalytics(action) {
  try {
    const response = yield fetch(
      backendURL +
        `/api/savedPredictions${action.payload.grouping ? `?group=${action.payload.grouping}` : '?group=meeting'}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    if (response.ok && action.payload.grouping === 'meeting') {
      let body = yield response.json();
      yield put(
        actions.fetchAnalyticsSuccess(buildDataAnaylticsForMeetings(body)),
      );
    } else if (response.ok) {
      let body = yield response.json();
      let graphData = body.graphData;
      let fillerWordsDetails = body.fillerWordsDetails;
      // TBD: this is a workaround to get the average jargon per week for all words
      const jargonEntries = graphData.filter(a => a.key?.startsWith('JARGON'));
      const jargonAggregates = {};
      jargonEntries.forEach(entry => {
        const value = entry.value;
        const aggregation = entry.aggregation;
        const seriesDate = entry.seriesDate;
        if (!jargonAggregates[seriesDate]) {
          jargonAggregates[seriesDate] = {};
        }
        if (!jargonAggregates[seriesDate][aggregation]) {
          jargonAggregates[seriesDate][aggregation] = {
            count: 0,
            total: 0,
          };
        }
        jargonAggregates[seriesDate][aggregation].count++;
        jargonAggregates[seriesDate][aggregation].total += value;
      });
      const jargonAverages = Object.entries(jargonAggregates)
        .map(([seriesDate, aggregations]) => {
          const averageAggregations = {};
          Object.entries(aggregations).forEach(
            ([aggregation, { count, total }]) => {
              averageAggregations[aggregation] = {
                key: 'jargon',
                seriesDate,
                date: seriesDate,
                aggregation,
                type: 'number',
                value: total / count,
              };
            },
          );
          return Object.values(averageAggregations);
        })
        .flat()
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });

      graphData = graphData.concat(jargonAverages);
      yield put(
        actions.fetchAnalyticsSuccess({
          graphData,
          fillerWordsDetails,
        }),
      );
    } else {
      yield put(actions.fetchAnalyticsFailed('fetch analytics Failed'));
    }
  } catch (err) {
    console.log('fetch analytics failed', err);
    yield put(actions.fetchAnalyticsFailed('fetch analytics Failed'));
  }
}

/**
 *
 */
function* doFetchCallQualitativeAnalysis() {
  try {
    const response = yield fetch(backendURL + '/api/qualitativeAnalysis', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    });
    if (response.ok) {
      const body = yield response.json();
      yield put(actions.fetchCallQualitativeAnalysisSuccess(body.data));
    } else {
      yield put(
        actions.fetchCallQualitativeAnalysisFailed('fetch analytics Failed'),
      );
    }
  } catch (err) {
    console.log('fetch analytics failed', err);
    yield put(
      actions.fetchCallQualitativeAnalysisFailed('fetch analytics Failed'),
    );
  }
}

/**
 *
 * @param action
 */
function* doPollTranscriptId(action) {
  try {
    const { response } = yield race({
      response: fetch(
        backendURL + `/api/calls/${action.payload}/pollTranscriptionId`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `jwt ${localStorage.getItem('authToken')}`,
          },
        },
      ),
      timeout: delay(5000), // 5 seconds timeout
    });

    if (response) {
      if (response.ok) {
        const body = yield response.json();
        if (body.success) {
          yield put(actions.pollTranscriptIdSuccess(body));
        } else {
          yield put(actions.pollTranscriptIdFailed());
        }
      }
    } else {
      yield put(actions.pollTranscriptIdFailed('fetch transcription Failed'));
    }
  } catch (err) {
    console.log('fetch transcription failed', err);
    yield put(actions.pollTranscriptIdFailed('fetch transcription Failed'));
  }
}

/**
 *
 * @param action
 */
function* doUpdateQualitativeAnalysis(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/qualitativeAnalysis/${action.payload.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(action.payload),
      },
    );
    const body = yield response.json();
    yield put(actions.updateQualitativeAnalysisSuccess(body.data));
  } catch (err) {
    console.log('save qualitative failed', err);
    yield put(
      actions.updateQualitativeAnalysisFailed(
        'Save Qualitative Analysis Failed',
      ),
    );
  }
}

/**
 *
 * @param action
 */
function* doDirectUploadFile(action) {
  // file name

  const {
    duration,
    callType,
    date,
    noOfSpeakers,
    file: fileContent,
  } = action.payload;
  const { type: fileType, name: fileName } = fileContent;
  try {
    const response = yield fetch(backendURL + '/api/calls/uploadUrl', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({
        fileName,
        fileType,
      }),
    });
    const body = yield response.json();
    const uploadUrl = body.uploadUrl;
    const callId = body.callId;
    const key = body.key;
    const controller = new AbortController();
    yield put(actions.setuploadController(controller));
    yield fetch(uploadUrl, {
      method: 'PUT',
      body: fileContent,
      signal: controller.signal,
    });
    yield put(actions.directUploadFileSuccess());
    yield put(actions.setuploadController(null));
    yield put(
      actions.saveCall({
        key,
        callId,
        fileName,
        duration,
        callType,
        date,
        noOfSpeakers,
      }),
    );
  } catch (err) {
    console.log('upload failed', err);

    const user = yield select(selectUser);

    yield call(doSendSlackAlert, {
      type: 'Upload Call Failed - FE side',
      message: 'Upload Call Failed - FE side',
      ctx: {
        message: err?.message,
        duration,
        callType,
        date,
        noOfSpeakers,
        user,
      },
    });
  }
}

/**
 *
 * @param action
 */
function* doFetchUserData(action) {
  if (!action.payload.email) {
    return;
  }
  const userResponse = yield fetch(
    `${backendURL}/api/users?email=${encodeURIComponent(action.payload.email)}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    },
  );
  const userResponseData = yield userResponse.json();

  if (userResponseData.statusCode > 400) {
    yield put(actions.logout());
  } else {
    yield put(actions.fetchUserDataSuccess(userResponseData));
  }
}

/**
 *
 */
function* toggleMode() {
  try {
    const response = yield fetch(`${backendURL}/api/users/toggleMode`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
      method: 'PUT',
      body: '',
    });
    const user = yield response.json();
    yield put(actions.toggleModeSuccess(user));
  } catch (err) {
    console.log('toggle mode failed', err);
    yield put(actions.toggleModeFailed('Toggle Mode Failed'));
  }
}

/**
 *
 * @param action
 */
function* doSendSlackAlert(action) {
  try {
    yield fetch(`${backendURL}/api/alerts/slack`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
      method: 'POST',
      body: JSON.stringify({
        type: action?.type || 'UNKNOWN',
        message: action?.message || 'UNKNOWN',
        ctx: {
          ...action?.ctx,
        },
      }),
    });
  } catch (err) {
    console.log('Send slack alert failed', err);
  }
}

/**
 *
 */
export function* homeSaga() {
  yield takeLatest(actions.checkLogin.type, doCheckLogin);
  yield takeLatest(actions.login.type, doLogin);
  yield takeLatest(actions.register.type, doRegister);
  yield takeLatest(actions.fetchUserData.type, doFetchUserData);
  yield takeLatest(actions.fetchCalls.type, doFetchCalls);
  yield takeLatest(actions.fetchCallAudio.type, doFetchCallAudio);
  yield takeLatest(actions.fetchCallTranscript.type, doFetchCallTranscript);
  yield takeLatest(actions.fetchCallAnalysis.type, doFetchCallAnalysis);
  yield takeLatest(actions.saveCall.type, doSaveCall);
  yield takeLatest(actions.updateCall.type, doUpdateCall);
  yield takeLatest(actions.updateTranscript.type, doUpdateTranscript);
  yield takeLatest(actions.deleteCall.type, doDeleteCall);
  yield takeLatest(actions.logout.type, doLogout);
  yield takeLatest(actions.pollCallSlowAnalysis.type, doPollCallSlowAnalysis);
  yield takeLatest(actions.fetchAnalytics.type, doFetchAnalytics);
  yield takeLatest(actions.setAnalyticsGrouping.type, doFetchAnalytics);
  yield takeLatest(actions.fetchCallStatuses, doFetchCallStatuses);
  yield takeLatest(
    actions.fetchCallQualitativeAnalysis.type,
    doFetchCallQualitativeAnalysis,
  );
  yield takeLatest(
    actions.updateQualitativeAnalysis.type,
    doUpdateQualitativeAnalysis,
  );
  yield takeLatest(actions.pollTranscriptId.type, doPollTranscriptId);
  yield takeLatest(actions.directUploadFile.type, doDirectUploadFile);
  yield takeLatest(actions.toggleMode.type, toggleMode);
  yield takeLatest(actions.sendSlackAlert.type, doSendSlackAlert);
}
