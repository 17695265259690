import React, { useState } from 'react';

import { Button } from '@/components/Button/Button';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { Checkbox } from '@/components/Shared/Checkbox';
import { ONE_YEAR, setCookie } from '@/utils/cookie';

export const SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE =
  'SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE';

interface AddAssistantSuccessProps {
  onClose: () => void;
}

export const AddAssistantSuccess: React.FC<AddAssistantSuccessProps> = ({
  onClose,
}) => {
  const { setIsEventAdded } = useRecordingCalendarStore();

  const [isChecked, setIsChecked] = useState(true);

  const handleDoneClick = () => {
    if (isChecked) {
      setCookie(SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE, 'false', ONE_YEAR);
    }

    setIsEventAdded(false);
    onClose();
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 py-10 px-12">
      <div className="w-14 h-14 flex items-center justify-center relative">
        <img src="/recording-platforms/google-video.svg" />
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <span className="text-dark text-xl font-bold">
          SilkChart Assistant has been invited to the meeting
        </span>
        <span className="text-main-gray-dark text-sm tracking-[-0.14px]">
          Once joined, Assistant will automatically start recording your meeting
        </span>
      </div>
      <div>
        <div className="relative">
          <Checkbox
            size={16}
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            label="Don't show this again"
            labelClasses="flex items-center gap-1.5"
          />
        </div>
      </div>
      <Button
        onClick={handleDoneClick}
        variant="primary"
        className="min-w-[100px]"
      >
        <span>Done</span>
      </Button>
    </div>
  );
};
