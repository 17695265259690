export class InsightsMetricsHelper {
  static orderMap = {
    averageSpeechPace: 1,
    averageFillerWords: 2,
    averageQuestionsAsked: 3,
    averageLongestMonologue: 4,
    averageTalkPercentage: 5,
  };

  static metricResultText = metricStatus => {
    switch (metricStatus) {
      case null:
        return {
          text: '-',
          color: '#B9C7D7',
          bgColor: '#FFF',
        };
      case 'good':
        return {
          text: 'Great 👍',
          color: '#0FB600',
          bgColor: '#0FB6001A',
        };
      case 'bad':
        return {
          text: 'Improvement Opportunity ⚡',
          color: '#E74029',
          bgColor: '#E740291A',
        };
      case 'okay':
        return {
          text: 'Okay 👌',
          color: '#F89B11',
          bgColor: '#F89B111A',
        };
    }
  };

  static titleMap = {
    averageLongestMonologue: {
      title: 'Customer Monologue',
    },
    averageSpeechPace: {
      title: 'Speech pace',
    },
    averageTalkPercentage: {
      title: 'Talk ratio',
    },
    averageQuestionsAsked: {
      title: 'Questions asked',
    },
    averageFillerWords: {
      title: 'Filler words',
    },
  };

  static rangesByMetric = {
    averageSpeechPace: metricResult => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult >= 110 && metricResult <= 170:
          return 'good';
        case metricResult > 200:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageTalkPercentage: metricResult => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult < 40:
          return 'good';
        case metricResult > 60:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageLongestMonologue: metricResult => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult > 60:
          return 'good';
        case metricResult < 30:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageQuestionsAsked: metricResult => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult >= 9 && metricResult <= 20:
          return 'good';
        case metricResult < 4:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageFillerWords: metricResult => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult < 2:
          return 'good';
        case metricResult >= 20:
          return 'bad';
        default:
          return 'okay';
      }
    },
  };

  static setMetricsInfo(metrics) {
    const metricsWithStatuses = [];

    Object.keys(metrics).forEach(key => {
      const metricValue = metrics[key];
      const calculateStatusFunction = this.rangesByMetric[key];

      if (!calculateStatusFunction) return;

      const status = calculateStatusFunction(metricValue);

      metricsWithStatuses.push({
        key,
        order: this.orderMap[key] || 999,
        status,
        value: metricValue,
        text: {
          ...this.titleMap[key],
          result: this.metricResultText(status),
        },
      });
    });

    return metricsWithStatuses;
  }
}
