export const SseEventURLs = Object.freeze({
  BotJoinedCalendarEventURL: `${process.env.REACT_APP_BACKEND_URL}/api/calendar/sse/subscribe`,
  CallProcessingEventURL: `${process.env.REACT_APP_BACKEND_URL}/api/calls/sse/subscribe`,
});

export enum SseEvents {
  BotJoinedCalendarEvent = 'bot_joined_calendar_event',
  CallProcessingFinished = 'call_processing_finished',
}

export const SseEventsToURL: Record<SseEvents, string> = Object.freeze({
  [SseEvents.BotJoinedCalendarEvent]: SseEventURLs.BotJoinedCalendarEventURL,
  [SseEvents.CallProcessingFinished]: SseEventURLs.CallProcessingEventURL,
});
