import { Search } from 'react-feather';

import { Input } from '@/components/Input/Input';
import cn from '@/utils/cn';

interface CallsListSearchProps {
  show: boolean;
  value: string;
  onChange: (s: string) => void;
}

export const CallsListSearch: React.FC<CallsListSearchProps> = ({
  show,
  value,
  onChange,
}: CallsListSearchProps) => (
  <div className={cn(!show && 'invisible', 'relative mt-8 mx-8')}>
    <Input
      value={value}
      onChange={e => {
        onChange(e.target.value);
      }}
      placeholder="Search Calls"
      leftSlot={<Search size={20} />}
    />
  </div>
);
