import { useMutation } from '@tanstack/react-query';

import { resetPassword as resetPasswordAPICall } from '@/api/auth/reset-password/resetPassword';

export const useResetPassword = () => {
  const { mutateAsync: resetPassword, ...rest } = useMutation({
    mutationFn: (params: { resetPasswordToken: string; password: string }) =>
      resetPasswordAPICall(params),
    onSuccess: data => {
      localStorage.setItem('authToken', data.token);
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.error('Something went wrong');
    },
  });

  return { resetPassword, ...rest };
};
