import { useUploadModalStore } from '../UploadCallModal/hooks/useUploadModalStore';

export const UploadLink = () => {
  const { openUploadModal } = useUploadModalStore();

  const handleClick = () => {
    openUploadModal();
  };

  return (
    <span className="text-[#2C6CF6] cursor-pointer" onClick={handleClick}>
      Upload
    </span>
  );
};
