import dayjs from 'dayjs';

import { getCookie, setCookie } from '@/utils/cookie';

const SKIP_CONNECT_YOUR_CALENDAR_COOKIE_NAME =
  'SKIP_CONNECT_YOUR_CALENDAR_COOKIE';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

/**
 * Service for managing the connect calendar cookie
 */
export class ConnectCalendarCookieService {
  /**
   * Get the index from the cookie
   * @returns index
   */
  static getCookie() {
    const cookieValue = getCookie(SKIP_CONNECT_YOUR_CALENDAR_COOKIE_NAME);
    return Boolean(cookieValue);
  }

  /**
   * Set the calendar cookie
   */
  static setSkipConnectCalendarCookie() {
    setCookie(SKIP_CONNECT_YOUR_CALENDAR_COOKIE_NAME, 'true', ONE_DAY_MS);
  }

  /**
   * Checks if based on a set of conditions the connect calendar should be shown
   * @param userCreationDate the date the user was created at
   * @returns if the connect calendar should be shown
   */
  static shouldShowConnectCalendar(userCreationDate: Date) {
    //Show only if the user was created in the last 5 days
    const daysSinceCreated = dayjs().diff(userCreationDate, 'day');
    const cookie = this.getCookie();

    if (daysSinceCreated > 5 || cookie) {
      return false;
    }
    return true;
  }
}
