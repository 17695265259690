import { API } from '@/api/base';
import { Response } from '@/api/response';
import { FeatureFlags } from '@/hooks/useGetFeatureFlags';
import logger from '@/utils/logger';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getFeatureFlags: `${backendURL}/api/app-config/feature-flags`,
};

export type FeatureFlagsResponse = Record<FeatureFlags, string>;

export const getAppFeatureFlags =
  () => async (): Promise<FeatureFlagsResponse | undefined> => {
    try {
      const response = await API(endpoints.getFeatureFlags, {
        method: 'GET',
      });

      const result: Response<FeatureFlagsResponse> = await response.json();

      logger.info(result, 'fetch feature flags');

      return result.data ?? null;
    } catch (err) {
      logger.error('fetch feature flags', err);
      return undefined;
    }
  };
