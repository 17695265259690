import React, { ReactElement, useEffect } from 'react';
import { Calendar, ChevronDown } from 'react-feather';
import Select, { components } from 'react-select';

import { useInsightsAcrossCallsStore } from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { useSubscription } from '@/hooks/useSubscription';
import cn from '@/utils/cn';

export interface TimeRangeOption {
  label: string;
  value: InsightsAcrossCallsTimeRangeOptionsValue;
  icon: ReactElement;
}

export enum InsightsAcrossCallsTimeRangeOptionsValue {
  Week = 'week',
  TwoWeeks = 'twoWeeks',
  Month = 'month',
  ThreeMonths = 'threeMonths',
}

export const InsightsAcrossCallsTimeRangeOptions: TimeRangeOption[] = [
  {
    label: 'Last week',
    value: InsightsAcrossCallsTimeRangeOptionsValue.Week,
    icon: <Calendar size={20} />,
  },
  {
    label: 'Last 2 weeks',
    value: InsightsAcrossCallsTimeRangeOptionsValue.TwoWeeks,
    icon: <Calendar size={20} />,
  },
  {
    label: 'Last month',
    value: InsightsAcrossCallsTimeRangeOptionsValue.Month,
    icon: <Calendar size={20} />,
  },
  {
    label: 'Last 3 months',
    value: InsightsAcrossCallsTimeRangeOptionsValue.ThreeMonths,
    icon: <Calendar size={20} />,
  },
];

export const InsightsAcrossCallsTimeRangeDropdown: React.FC = () => {
  const { subscriptionData } = useSubscription();

  const { selectedDateOption, setSelectedDateOption } =
    useInsightsAcrossCallsStore();

  useEffect(() => {
    if (!selectedDateOption)
      setSelectedDateOption(InsightsAcrossCallsTimeRangeOptions[0]);
  }, []);

  const handleChange = (selectedOption: TimeRangeOption) => {
    if (selectedDateOption === selectedOption) return;

    const possibleOptions = InsightsAcrossCallsTimeRangeOptions.map(
      el => el.value,
    );
    if (possibleOptions.includes(selectedOption.value)) {
      setSelectedDateOption(selectedOption);
    }
  };
  return (
    <div>
      <Select
        isDisabled={subscriptionData?.isDemoMode}
        name="options"
        options={InsightsAcrossCallsTimeRangeOptions}
        value={InsightsAcrossCallsTimeRangeOptions.find(
          (option: TimeRangeOption) =>
            option.value === selectedDateOption?.value,
        )}
        onChange={handleChange}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          SingleValue,
          DropdownIndicator,
        }}
        styles={{
          control: (provided, state) => ({
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid var(--main-gray-main, #ECECEC)',
            borderRadius: 10,
            boxShadow: 'none',
            padding: '12px 10px 10px 10px',
            backgroundColor: '#FFF',
            cursor: 'pointer',
          }),
          indicatorsContainer: styles => ({
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            marginLeft: 8,
          }),
          dropdownIndicator: (provided, state) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1C1C1C',
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontWeight: 500,
            backgroundColor: '#fff',
            color: '#1C1C1C',
            fontSize: 14,
            padding: 8,
            ':active': {
              backgroundColor: '#fff',
            },
            ':hover': {
              backgroundColor: '#F6F6F6',
              borderRadius: 4,
            },
          }),
          menu: (styles, state) => ({
            ...styles,
            width: '100%',
            minWidth: '171px',
            boxShadow:
              '0px 2px 6px -2px rgba(24, 39, 75, 0.06), 0px 1px 6px -2px rgba(24, 39, 75, 0.10)',
            borderRadius: 10, // bottom left and bottom right radius 10
            padding: '8px 12px 8px 12px',
            gap: 4,
            marginTop: 2,
            right: 0,
            backgroundColor: '#FFF',
          }),
          menuList: styles => ({
            ...styles,
            padding: 0,
          }),
          singleValue: styles => ({
            ...styles,
            color: '#1C1C1C',
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
          }),
          valueContainer: styles => ({
            ...styles,
            padding: 0,
            input: { height: 0 },
          }),
        }}
      />
    </div>
  );
};
const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center w-full h-full">
        <div className="flex-1">
          <div
            className={cn(
              'text-sm',
              props.isSelected === true && 'text-accent-blue',
            )}
          >
            {props.label}
          </div>
        </div>
      </div>
    </components.Option>
  );
};
const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center w-full h-full">
        <div
          className={cn(
            'h-[20px] w-[20px]',
            props.isDisabled && 'opacity-[.4]',
          )}
        >
          {props.data.icon}
        </div>
        <div className="flex-1 ml-1">
          <div className={cn('text-sm', props.isDisabled && 'opacity-[.4]')}>
            {props.data.label}
          </div>
        </div>
      </div>
    </components.SingleValue>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <div className="h-[16px] w-[16px]">
      <ChevronDown
        className={cn(
          props.isDisabled && 'opacity-[.4]',
          `transition ease-in-out ${props.selectProps.menuIsOpen ? 'rotate-180' : ''}`,
        )}
        size={16}
      />
    </div>
  );
};
