import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from '@/pages/HomePage/slice/selectors';

import { useLeadershipDashboardSlice } from '@/pages/LeadershipDashboard/slice';
import {
  selectAveragePerformance,
  selectTeamInsights,
} from '@/pages/LeadershipDashboard/slice/selectors';
import { selectTimeRangeFilter } from '@/pages/LeadershipPage/slice/selectors';
import { ChartDatasetsConst } from '../../Shared/consts/ChartDatasetsConst';
import { options } from '@/components/InsightsAcrossCallsPage/consts/InsightsAcrossCallsChartOptionsConst';
import { InsightsMetricsHelper } from './helpers/InsightsMetricsHelper';
import InsightsMetricItem from './InsightsMetricItem';
import { mock } from './mock';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { RecommendedRangesConst } from '@/components/Shared/consts/RecommendedRangesConst';

const AverageInsightsBlock = () => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipDashboardSlice();
  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const currentTimeRangeFilter = useSelector(selectTimeRangeFilter);
  const teamInsights = useSelector(selectTeamInsights);
  const averagePerformance = useSelector(selectAveragePerformance);

  const [selectedMetric, setSelectedMetric] = useState(null);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [metricsWithInfo, setMetricsWithInfo] = useState([]);

  const [chartInstance, setChartInstance] = useState(null);
  const [dataPoints, setDataPoints] = useState([]);

  const datasets = ChartDatasetsConst(data);

  useEffect(() => {
    if (!selectedMetric) return;

    dispatch(
      actions.fetchTeamInsights({
        timeFilter: currentTimeRangeFilter,
        metricKey: selectedMetric.key,
      }),
    );
  }, [selectedMetric, currentTimeRangeFilter]);

  useEffect(() => {
    if (!selectedMetric) return;
    if (!teamInsights?.length && !useMock) return;

    setLabels(
      useMock
        ? mock.labels
        : teamInsights.map(el => dayjs.utc(el.day).format('MMM DD')),
    );
    setData(
      useMock
        ? mock.data[selectedMetric.key]
        : teamInsights.map(el => el[selectedMetric.key]),
    );
  }, [teamInsights]);

  useEffect(() => {
    if (!averagePerformance) return;

    const metricsWithInfo = InsightsMetricsHelper.setMetricsInfo(
      useMock ? mock.performance : averagePerformance,
    );

    if (!selectedMetric) setSelectedMetric(metricsWithInfo[0]);

    setMetricsWithInfo(metricsWithInfo);
  }, [averagePerformance]);

  const calculateDataPoints = useCallback(() => {
    if (!chartInstance) {
      return;
    }
    const meta = chartInstance.getDatasetMeta(0);

    const points = meta.data.map(data => ({
      x: data.x + window.scrollX,
      y: data.y + window.scrollY,
    }));

    setDataPoints(points);
  }, [chartInstance]);

  const chartOptions = options({
    recommendedRangeStart: RecommendedRangesConst[selectedMetric?.key]?.[0],
    recommendedRangeEnd: RecommendedRangesConst[selectedMetric?.key]?.[1],
    onAnimationComplete: () => {
      calculateDataPoints();
    },
  });

  useEffect(() => {
    window.addEventListener('scroll', calculateDataPoints);

    return () => {
      window.removeEventListener('scroll', calculateDataPoints);
    };
  }, [calculateDataPoints]);

  if (!selectedMetric) return;

  return (
    <div className="mb-12">
      <div>
        <span className="text-[#5C6A82] text-base font-semibold">
          Average Insights
        </span>
      </div>
      <div className="mt-4 p-6 rounded-[20px] border border-1 border-[#ECECEC] shadow-sm">
        <div className="flex flex-row">
          {metricsWithInfo
            ?.sort((a, b) => a.order - b.order)
            .map((metricInfo, index) => (
              <InsightsMetricItem
                key={index}
                metricInfo={metricInfo}
                isSelected={selectedMetric.key === metricInfo.key}
                setSelectedMetric={setSelectedMetric}
              />
            ))}
        </div>
        <div className="mt-6 flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <span className="text-sm text-[#1C1C1C] font-normal">
              Average Performance:
            </span>
            <div
              className="ml-1 rounded-lg pl-2 pt-1 pr-2 pb-1 h-[25px] flex items-center"
              style={{
                backgroundColor: selectedMetric.text.result.bgColor,
              }}
            >
              <span
                className="font-semibold text-sm"
                style={{
                  color: selectedMetric.text.result.color,
                }}
              >
                {selectedMetric.text.result.text}
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              <div className="w-[24px] h-[6px] rounded bg-[#2C6CF6]"></div>
              <span className="ml-1 font-normal text-sm text-[#5C6A82]">
                Words per minute
              </span>
            </div>
            <div className="flex flex-row items-center ml-6">
              <div className="w-[16px] h-[16px] bg-[#2c6cf60f] rounded-sm border border-1 border-[#2c6cf652]"></div>
              <span className="ml-1 font-normal text-sm text-[#5C6A82]">
                Recommended range
              </span>
            </div>
          </div>
        </div>
        <div className="h-[431px] relative">
          <Line
            options={{
              ...chartOptions,
              onResize: chartInstance => {
                setChartInstance(chartInstance);
              },
            }}
            data={{
              datasets,
              labels,
            }}
            style={{ height: '100%' }}
          />
          {dataPoints.map((point, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: point.x,
                top: point.y,
                transform: 'translate(-50%, -50%)',
              }}
              className="w-3.5 h-3.5 rounded-full flex items-center justify-center"
            >
              <WithHoverTooltip
                tooltip={
                  <div className="overflow-y-auto max-h-[280px] max-w-[272px] p-3 text-start">
                    {useMock
                      ? datasets[0].data[index]
                      : teamInsights[index][selectedMetric.key]}
                  </div>
                }
                placement="bottom"
                tooltipContainerClassName="p-0 m-0"
              >
                <div className="w-3.5 h-3.5 rounded-full" />
              </WithHoverTooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AverageInsightsBlock;
