import { useQuery } from '@tanstack/react-query';

import {
  FeatureFlagsResponse,
  getAppFeatureFlags as getFeatureFlagsAPI,
} from '@/api/app-config/getFeatureFlags';

export const useGetFeatureFlags = () => {
  const { data: featureFlags, ...rest } = useQuery<
    FeatureFlagsResponse | undefined
  >({
    queryFn: getFeatureFlagsAPI(),
    queryKey: ['featureFlags'],
  });

  return { featureFlags, ...rest };
};

export const useGetFeatureFlagByKey = (key: FeatureFlags) => {
  const featureFlags = useGetFeatureFlags();

  if (!featureFlags.featureFlags) {
    return undefined;
  }
  return featureFlags.featureFlags[key];
};

export enum FeatureFlags {
  /**
   * Enable the real-time transcription feature flag. It can be a string containing the value 'true' or 'false'.
   */
  EnableRealTimeTranscription = 'EnableRealTimeTranscription',
}
