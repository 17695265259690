export const teamsMock = [
  {
    firstName: 'Ruben',
    lastName: 'Dias',
    playbookFulfilledPercentage: 96,

    'default-playbook-demo-personalized-on-need': 97,
    'default-playbook-objection': 94,
    'default-playbook-ending-next-steps': 87,

    averageSpeechPace: 188,
    averageTalkPercentage: 47,

    'default-playbook-demo-story': 59,
    'default-playbook-intro-agenda': 48,
    'default-playbook-intro-rapport': 48,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '1',
    'default-playbook-ending-schedule-followup': 83,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Mira',
    lastName: 'Mango',
    playbookFulfilledPercentage: 94,

    'default-playbook-demo-personalized-on-need': 92,
    'default-playbook-objection': 95,
    'default-playbook-ending-next-steps': 92,

    averageSpeechPace: 209,
    averageTalkPercentage: 41,

    'default-playbook-demo-story': 64,
    'default-playbook-intro-agenda': 72,
    'default-playbook-intro-rapport': 54,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '2',
    'default-playbook-ending-schedule-followup': 90,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Aspen',
    lastName: 'Philips',
    playbookFulfilledPercentage: 92,

    'default-playbook-demo-personalized-on-need': 93,
    'default-playbook-objection': 91,
    'default-playbook-ending-next-steps': 81,

    averageSpeechPace: 172,
    averageTalkPercentage: 52,

    'default-playbook-demo-story': 72,
    'default-playbook-intro-agenda': 78,
    'default-playbook-intro-rapport': 62,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '3',
    'default-playbook-ending-schedule-followup': 72,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Talan',
    lastName: 'Calzoni',
    playbookFulfilledPercentage: 87,

    'default-playbook-demo-personalized-on-need': 89,
    'default-playbook-objection': 80,
    'default-playbook-ending-next-steps': 72,

    averageSpeechPace: 194,
    averageTalkPercentage: 68,

    'default-playbook-demo-story': 71,
    'default-playbook-intro-agenda': 71,
    'default-playbook-intro-rapport': 71,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '4',
    'default-playbook-ending-schedule-followup': 92,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Mark',
    lastName: 'Press',
    playbookFulfilledPercentage: 85,

    'default-playbook-demo-personalized-on-need': 72,
    'default-playbook-objection': 81,
    'default-playbook-ending-next-steps': 75,

    averageSpeechPace: 154,
    averageTalkPercentage: 50,

    'default-playbook-demo-story': 58,
    'default-playbook-intro-agenda': 91,
    'default-playbook-intro-rapport': 83,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '3',
    'default-playbook-ending-schedule-followup': 69,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Emerson',
    lastName: 'Mango',
    playbookFulfilledPercentage: 79,

    'default-playbook-demo-personalized-on-need': 84,
    'default-playbook-objection': 69,
    'default-playbook-ending-next-steps': 80,

    averageSpeechPace: 181,
    averageTalkPercentage: 29,

    'default-playbook-demo-story': 50,
    'default-playbook-intro-agenda': 63,
    'default-playbook-intro-rapport': 49,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '6',
    'default-playbook-ending-schedule-followup': 55,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Skylar',
    lastName: 'Herwitz',
    playbookFulfilledPercentage: 71,

    'default-playbook-demo-personalized-on-need': 67,
    'default-playbook-objection': 49,
    'default-playbook-ending-next-steps': 69,

    averageSpeechPace: 190,
    averageTalkPercentage: 61,

    'default-playbook-demo-story': 64,
    'default-playbook-intro-agenda': 36,
    'default-playbook-intro-rapport': 65,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '7',
    'default-playbook-ending-schedule-followup': 73,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Aspen',
    lastName: 'Lubin',
    playbookFulfilledPercentage: 59,

    'default-playbook-demo-personalized-on-need': 71,
    'default-playbook-objection': 61,
    'default-playbook-ending-next-steps': 39,

    averageSpeechPace: 175,
    averageTalkPercentage: 51,

    'default-playbook-demo-story': 90,
    'default-playbook-intro-agenda': 50,
    'default-playbook-intro-rapport': 62,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '8',
    'default-playbook-ending-schedule-followup': 46,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Carter',
    lastName: 'Franci',
    playbookFulfilledPercentage: 56,

    'default-playbook-demo-personalized-on-need': 50,
    'default-playbook-objection': 55,
    'default-playbook-ending-next-steps': 81,

    averageSpeechPace: 128,
    averageTalkPercentage: 49,

    'default-playbook-demo-story': 67,
    'default-playbook-intro-agenda': 67,
    'default-playbook-intro-rapport': 54,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '9',
    'default-playbook-ending-schedule-followup': 50,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Marcus',
    lastName: 'Dias',
    playbookFulfilledPercentage: 54,

    'default-playbook-demo-personalized-on-need': 42,
    'default-playbook-objection': 47,
    'default-playbook-ending-next-steps': 51,

    averageSpeechPace: 179,
    averageTalkPercentage: 47,

    'default-playbook-demo-story': 51,
    'default-playbook-intro-agenda': 94,
    'default-playbook-intro-rapport': 76,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '10',
    'default-playbook-ending-schedule-followup': 54,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Zain',
    lastName: 'Siphron',
    playbookFulfilledPercentage: 38,

    'default-playbook-demo-personalized-on-need': 31,
    'default-playbook-objection': 35,
    'default-playbook-ending-next-steps': 37,

    averageSpeechPace: 172,
    averageTalkPercentage: 43,

    'default-playbook-demo-story': 81,
    'default-playbook-intro-agenda': 50,
    'default-playbook-intro-rapport': 77,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '11',
    'default-playbook-ending-schedule-followup': 28,
    email: 'demo@silkchart.com',
  },
  {
    firstName: 'Ryan',
    lastName: 'Calzoni',
    playbookFulfilledPercentage: 34,

    'default-playbook-demo-personalized-on-need': 28,
    'default-playbook-objection': 37,
    'default-playbook-ending-next-steps': 87,

    averageSpeechPace: 187,
    averageTalkPercentage: 59,

    'default-playbook-demo-story': 74,
    'default-playbook-intro-agenda': 54,
    'default-playbook-intro-rapport': 58,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '12',
    'default-playbook-ending-schedule-followup': 49,
    email: 'demo@silkchart.com',
  },
];
