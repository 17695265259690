import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useState } from 'react';

import { SseEvents, SseEventsToURL } from '@/hooks/sse/SseEventsEnum';
import logger from '@/utils/logger';

/**
 * Custom hook to subscribe to Server-Sent Events.
 */
export function useSSE<T>(event: SseEvents) {
  const [data, setData] = useState<T | null>(null);

  const url = SseEventsToURL[event];

  useEffect(() => {
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    });

    eventSource.onopen = () => {
      logger.info('Connected to the server for events of type:', event);
    };

    eventSource.onerror = err => {
      logger.error(err, 'Error occurred:');
    };

    eventSource.onmessage = event => {
      try {
        logger.info(event, 'Received data');

        const data = JSON.parse(event.data);
        logger.info(data, 'Parsed data');
        setData(data);
      } catch {
        logger.error('Something went wrong while receiving data');
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return { data };
}
