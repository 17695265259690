import React, { useRef } from 'react';
import { Check } from 'react-feather';

import cn from '@/utils/cn';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  size?: number;
  label?: string | React.ReactNode;
  labelClasses?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  size = 20,
  label,
  labelClasses,
  id,
  ...inputProps
}: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const isChecked = inputRef.current?.checked || inputProps.checked;
  const isEnabled = !inputProps.disabled;

  return (
    <label
      htmlFor={id}
      className={
        labelClasses ||
        'flex items-center gap-1 text-main-gray-dark enabled:hover:text-black select-none'
      }
    >
      <input
        ref={inputRef}
        id={id}
        type="checkbox"
        className={cn(
          `
            peer sr-only
            `,
        )}
        {...inputProps}
      />

      <div
        className={cn(
          `flex flex-col items-center justify-center w-[${size}px] h-[${size}px] flex-shrink-0
           border-main-gray-dark border-2
          bg-white appearance-none

          `,
          'peer-focus:ring-2 peer-focus:ring-accent-blue peer-focus:ring-offset-2',
          {
            'border-0 bg-accent-blue hover:!bg-accent-dark-blue': isChecked,
            'hover:bg-accent-dark-blue-12-percent': isEnabled,
            'border-main-gray checked:bg-main-gray-dark cursor-not-allowed':
              !isEnabled,
          },
        )}
      >
        <Check
          size={size || 20}
          className={cn('hidden pointer-events-none text-white', {
            block: isChecked,
          })}
        ></Check>
      </div>
      {label}
    </label>
  );
};
