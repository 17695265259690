import { Spinner } from 'flowbite-react';
import { useEffect } from 'react';
import { MessageCircle } from 'react-feather';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useTranscriptSSE } from '@/hooks/sse/useTranscriptSSE';
import {
  FeatureFlags,
  useGetFeatureFlagByKey,
} from '@/hooks/useGetFeatureFlags';
import logger from '@/utils/logger';
import { DisplayRecordingCallDuration } from '@/widgets/RecordWidget/components/DisplayRecordingCallDuration';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

import CallTranscriptViewer from './Assets/CallTranscriptViewer';

export const Record: React.FC = () => {
  const { isRecording, isCallUploading } = useCallRecordingStore(
    useShallow(state => ({
      isRecording: state.isRecording,
      isCallUploading: state.isCallUploading,
    })),
  );

  const { isBotRecording } = useBotRecordingStore(
    useShallow(state => ({
      isBotRecording: state.isBotRecording,
    })),
  );

  const enableRealTimeTranscription =
    useGetFeatureFlagByKey(FeatureFlags.EnableRealTimeTranscription) === 'true';

  if (isBotRecording && enableRealTimeTranscription) {
    return <BotRecordingState />;
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <Spinner className="fill-accent-blue h-16 w-16" />
      <div className="flex flex-col items-center gap-2">
        <p className="text-dark font-bold text-xl">
          {(isRecording || isBotRecording) && 'Recording...'}
          {isCallUploading && 'Saving your call...'}
        </p>
        <p className="text-main-gray-dark font-medium text-sm">
          {(isRecording || isBotRecording) && (
            <DisplayRecordingCallDuration
              format="formatTimestamp"
              recordingType={isBotRecording ? 'bot' : 'user'}
            />
          )}
          {isCallUploading &&
            'This will only take a few seconds, please do not close this page'}
        </p>
      </div>
    </div>
  );
};

/**
 * BotRecordingState component
 */
export function BotRecordingState() {
  const { id } = useParams();

  const { data } = useTranscriptSSE(id || '');

  const hasTranscription =
    data !== null && data.utterances.length > 0 ? true : false;

  useEffect(() => {
    logger.info({ id, hasTranscription, data }, 'BotRecordingState');
  }, [id, hasTranscription, data]);

  if (!hasTranscription) {
    return (
      <div
        className="flex p-4 rounded-2xl bg-white border border-[#ebebeb] flex-col justify-center items-center gap-4"
        style={{
          height: 'calc(100dvh - 200px)',
        }}
      >
        <div className="w-14 h-14 bg-opacity-10 bg-[#0046df] rounded-full items-center justify-center flex">
          <MessageCircle className="text-[#0046df] w-[40px] h-[40px]" />
        </div>

        <div className="text-main-gray-dark text-sm font-medium">
          Start talking and a live transcription of the call will appear here
        </div>
      </div>
    );
  }

  return <CallTranscriptViewer transcript={data!} />;
}
