import React from 'react';

import {
  CallTranscript,
  TranscriptUtterance,
} from '@/api/call/call-transcript.interface';
import { mapSpeakerNameToColor } from '@/components/HomePage/mapSpeakerNumberToColor';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';

interface CallTranscriptViewerProps {
  transcript: CallTranscript;
}

/**
 * Function to merge utterances based on the same speaker and a time gap < 10 seconds
 */
function mergeUtterances(
  utterances: TranscriptUtterance[],
): TranscriptUtterance[] {
  const merged: TranscriptUtterance[] = [];

  utterances.forEach(current => {
    if (current?.isFinal === false) {
      // Don't merge in-progress utterances
      merged.push({ ...current }); // Clone the object to avoid reference issues
      return;
    }

    const last = merged[merged.length - 1];

    // Check if the last utterance is from the same speaker and within the 10-second gap
    if (
      last &&
      last.speaker === current.speaker &&
      current.startTimeInMs - last.endTimeInMs <= 10000
    ) {
      // Create a new merged utterance instead of mutating `last`
      const updatedLast = {
        ...last,
        text: `${last.text} ${current.text}`,
        endTimeInMs: current.endTimeInMs,
        words: [...last.words, ...current.words],
      };

      // Replace the last utterance with the updated one
      merged[merged.length - 1] = updatedLast;
    } else {
      // Push a new utterance
      merged.push({ ...current }); // Clone the object
    }
  });

  return merged;
}
/**
 * Component to display the transcript of a call
 */
function CallTranscriptViewer({ transcript }: CallTranscriptViewerProps) {
  const transcriptContainerRef = React.useRef<HTMLDivElement>(null);
  const [mergedTranscriptUtterances, setMergedTranscriptUtterances] =
    React.useState<TranscriptUtterance[]>([]);

  // Sort and merge utterances
  React.useEffect(() => {
    const sortedUtterances = transcript.utterances.sort(
      (a, b) => a.startTimeInMs - b.startTimeInMs,
    );

    // Merge utterances
    const merged = mergeUtterances(sortedUtterances);
    setMergedTranscriptUtterances([...merged]);
  }, [transcript.utterances]);

  React.useEffect(() => {
    // If the transcript changes, scroll to the end of the ref div
    transcriptContainerRef.current?.scrollTo({
      top: transcriptContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [mergedTranscriptUtterances]);

  return (
    <div
      className="flex p-4 rounded-2xl bg-white border border-[#ebebeb] flex-col overflow-y-auto scroll-smooth"
      style={{
        height: 'calc(100dvh - 200px)',
      }}
      ref={transcriptContainerRef}
    >
      {mergedTranscriptUtterances.map((utterance, index) => {
        if (!utterance) {
          return null;
        }
        const speakerName = utterance.speaker ?? 'Unknown';
        const backgroundColor = mapSpeakerNameToColor(speakerName);

        return (
          <div key={index} className="mb-4">
            <div>
              <div className="flex items-center gap-1 mb-1">
                <div
                  className="rounded-full w-2 h-2"
                  style={{
                    backgroundColor,
                  }}
                ></div>
                <p className="text-sm font-semibold">{speakerName}</p>
                <span className="ml-1.5 text-[#717D96] text-sm">
                  {formatTimestamp(utterance.startTimeInMs)}
                </span>
              </div>
              <p
                className={cn({
                  'bg-[#1774F80F] rounded-l p-[4px] text-black':
                    utterance?.isFinal === false,
                })}
              >
                {utterance.text}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CallTranscriptViewer;
