import { create } from 'zustand';

import { deleteCookie, getCookie, setCookie } from '@/utils/cookie';

export const ONBOARDING_TOOLTIP_INDEX_COOKIE = 'ONBOARDING_TOOLTIP_INDEX';

/**
 * Service for managing the onboarding tooltip cookie
 */
export class OnboardingTooltipCookieService {
  /**
   * Get the index from the cookie
   * @returns index
   */
  static getIndexFromCookie() {
    const cookieValue = getCookie(ONBOARDING_TOOLTIP_INDEX_COOKIE);
    if (!cookieValue) {
      return null;
    }

    const result = parseInt(cookieValue);
    if (Number.isNaN(result)) {
      return null;
    }

    return result;
  }
  /**
   * Set the index to the cookie
   * @param index the index to set
   */
  static setIndexToCookie(index: number) {
    setCookie(
      ONBOARDING_TOOLTIP_INDEX_COOKIE,
      index.toString(),
      30 * 24 * 60 * 60 * 1000,
    );
  }
  /**
   * Remove the cookie
   */
  static removeCookie() {
    deleteCookie(ONBOARDING_TOOLTIP_INDEX_COOKIE);
  }

  /**
   * Start the onboarding
   */
  static startOnboarding() {
    this.setIndexToCookie(0);
  }

  /**
   * Finish the onboarding
   */
  static finishOnboarding() {
    this.removeCookie();
  }
}

interface OnboardingTooltipCookieStoreState {
  tooltipIndex: number;
  setTooltipIndex: (index: number) => void;
  startOnboarding: () => void;
  finishOnboarding: () => void;
}

export const useOnboardingTooltipCookieStore =
  create<OnboardingTooltipCookieStoreState>(set => ({
    tooltipIndex:
      OnboardingTooltipCookieService.getIndexFromCookie() !== null
        ? (OnboardingTooltipCookieService.getIndexFromCookie() as number)
        : -1,
    setTooltipIndex: index => {
      OnboardingTooltipCookieService.setIndexToCookie(index);
      set(() => ({ tooltipIndex: index }));
    },
    startOnboarding: () => {
      OnboardingTooltipCookieService.startOnboarding();
      set(() => ({ tooltipIndex: 0 }));
    },
    finishOnboarding: () => {
      OnboardingTooltipCookieService.finishOnboarding();
      set(() => ({ tooltipIndex: -1 }));
    },
  }));
