import React, { Dispatch, SetStateAction } from 'react';
import { Info } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { Button } from '@/components/Button/Button';
import { useUpcomingCallsModalStore } from '@/components/Calendar/UpcomingCalls/hooks/useUpcomingCallsModalStore';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import {
  CallUploadError,
  CallUploadStep,
  UploadState,
} from '@/components/UploadCallModal/UploadCallModal';
import {
  UploadCallWorkerMessage,
  UploadCallWorkerMessageTypes,
} from '@/components/UploadCallModal/utils/upload.interface';
import { useSubscription } from '@/hooks/useSubscription';
import { useHomeSlice } from '@/pages/HomePage/slice';
import { getAudioDuration } from '@/utils/getAudioDuration';
import logger from '@/utils/logger';

import { ErrorStep } from './steps/ErrorStep';
import { LoadingStep } from './steps/LoadingStep';
import { SelectFileStep } from './steps/SelectFileStep';

interface UploadStepsProps {
  closeModal: () => void;
  setIsDragging: (isDragging: boolean) => void;
  setUploadError: (item: CallUploadError | null) => void;
  uploadError: CallUploadError | null;
  setUploadState: Dispatch<SetStateAction<UploadState>>;
  uploadState: UploadState;
  reset: () => void;
  noUploadCallsLeft: boolean;
}

export const UploadSteps: React.FC<UploadStepsProps> = ({
  closeModal,
  setIsDragging,
  setUploadError,
  uploadError,
  setUploadState,
  uploadState,
  reset,
  noUploadCallsLeft,
}: UploadStepsProps) => {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const navigate = useNavigate();

  const { openSubscribeModal } = useSubscribeModalStore();
  const { openUpcomingCallsModal } = useUpcomingCallsModalStore();
  const { isFree, openStripeCustomerPortal } = useSubscription();

  const getUploadStep = () => {
    if (uploadError) {
      return CallUploadStep.Error;
    }
    if (uploadState.isPending) {
      return CallUploadStep.Loading;
    }
    return CallUploadStep.FileSelect;
  };
  const uploadStep = getUploadStep();

  const handleFileChange = async (file: File) => {
    const worker = new Worker(new URL('./uploadWorker.ts', import.meta.url));
    try {
      // Get audio duration
      const duration = await getAudioDuration(file);

      if (!duration) {
        logger.error('Failed to get audio duration');
      }

      //Use a web worker to handle the upload
      worker.postMessage({
        file,
        authToken: `jwt ${localStorage.getItem('authToken')}`,
        duration,
      });

      eventTracker.upload.start({ fileName: file.name, duration });

      worker.onmessage = ({ data: message }: { data: unknown }) => {
        const typedMessage = message as UploadCallWorkerMessage;

        switch (typedMessage.type) {
          case UploadCallWorkerMessageTypes.UPLOAD_IN_PROGRESS:
            setUploadState({
              progress: typedMessage.uploadProgress,
              isPending: true,
              createdCallId: null,
              error: null,
            });
            break;

          case UploadCallWorkerMessageTypes.UPLOAD_COMPLETE:
            logger.debug(typedMessage, 'Upload complete');
            setUploadState({
              progress: 0,
              isPending: false,
              createdCallId: typedMessage.createdCallId,
              error: null,
            });
            dispatch(actions.fetchCalls(null));
            closeModal();
            eventTracker.upload.complete({
              callId: typedMessage.createdCallId,
            });
            navigate(`/calls/${typedMessage.createdCallId}`);
            worker.terminate();
            break;

          case UploadCallWorkerMessageTypes.ERROR:
            setUploadState({
              progress: 0,
              isPending: false,
              createdCallId: null,
              error: typedMessage.error,
            });
            logger.error(typedMessage.error);
            throw typedMessage.error;

          default:
            logger.error('Unknown message type', typedMessage);
            setUploadError(CallUploadError.UnknownError);
            worker.terminate();
            break;
        }
      };
    } catch (error: unknown) {
      logger.error(error, 'Failed to create new call');
      setUploadError(CallUploadError.UnknownError);
      worker.terminate();
    }
  };

  const handleRecordClick = () => {
    closeModal();
    openUpcomingCallsModal();
  };

  const handleUpgradeClick = () => {
    closeModal();
    if (isFree) {
      openSubscribeModal();
      return;
    } else {
      openStripeCustomerPortal();
    }
  };

  if (noUploadCallsLeft) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Info size={32} className="text-accent-red mb-2" />
        <span className="text-base text-dark font-medium tracking-[-0.16px] mb-1">{`You've reached your upload limit`}</span>
        <span className="text-sm text-main-gray-dark font-normal tracking-[-0.14px]">
          You can still{' '}
          <span
            className="text-sm text-accent-blue font-semibold cursor-pointer"
            onClick={handleRecordClick}
          >
            record
          </span>{' '}
          unlimited number of calls
        </span>
        <Button variant="primary" className="mt-6" onClick={handleUpgradeClick}>
          Upgrade for unlimited uploads
        </Button>
      </div>
    );
  }

  return (
    <>
      {uploadStep === CallUploadStep.FileSelect && (
        <SelectFileStep
          onFileChange={handleFileChange}
          onError={setUploadError}
          onDragChange={setIsDragging}
        />
      )}
      {uploadStep === CallUploadStep.Error && (
        <ErrorStep uploadError={uploadError} onTryAgainClick={reset} />
      )}
      {uploadStep === CallUploadStep.Loading && <LoadingStep />}
    </>
  );
};
